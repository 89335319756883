import * as React from "react";
import { PlasmicTextInput, DefaultTextInputProps } from "./plasmic/remms_4_all/PlasmicTextInput";
import { TextInputRef } from "@plasmicapp/react-web";

type TextInputProps = DefaultTextInputProps & {
  onChange: (value: string | undefined) => void;
  value: string | undefined;
};

function TextInput_<T extends string | undefined>({ onChange, value, ...props }: TextInputProps, ref: TextInputRef) {
  const { plasmicProps } = PlasmicTextInput.useBehavior<DefaultTextInputProps>(props, ref);

  const inputProps = {
    value: value || "",
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange && onChange(e.target.value as T);
    },
  };

  return <PlasmicTextInput {...plasmicProps} input={inputProps} textarea={inputProps} />;
}

const TextInput = React.forwardRef(TextInput_);

export default Object.assign(TextInput, {
  __plumeType: "text-input",
});
