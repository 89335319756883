import * as React from "react";
import { PlasmicProcessStickyNav, DefaultProcessStickyNavProps } from "./plasmic/remms_4_all/PlasmicProcessStickyNav";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ProcessStickyNavProps extends DefaultProcessStickyNavProps {
  isSubmitting: boolean;
}

function ProcessStickyNav_({ isSubmitting, ...props }: ProcessStickyNavProps, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicProcessStickyNav
      root={{ ref }}
      submitButton={{
        isLoading: isSubmitting,
        isDisabled: isSubmitting,
      }}
      {...props}
    />
  );
}

const ProcessStickyNav = React.forwardRef(ProcessStickyNav_);
export default ProcessStickyNav;
