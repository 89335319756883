import * as React from "react";
import { RealtySnapshotResponse } from "../../api/types";
import ObjectDetailRow from "../ObjectDetailRow";
import { formatCurrencyInThousands } from "../../utils";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../../api/AuthenticatedApiService";
import { isSuccessful } from "../../api/apiEndpoint";
import { useApiErrorHandler } from "../../useApiErrorHandler";
import { ApplicationRoutes } from "../../ApplicationRoutes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash";

enum DropdownSelection {
  load = 1,
  download = 2,
  softDelete = 3,
}

interface DashboardSnapshotsListProps extends WithAuthenticatedApiServiceProps {
  snapshots: RealtySnapshotResponse[];
  onActiveChanged: () => void;
  realtyId: number;
}

const DashboardSnapshotsList: React.FunctionComponent<DashboardSnapshotsListProps> = ({
  snapshots,
  authenticatedApiService,
  onActiveChanged,
  realtyId,
}) => {
  const [isPending, setIsPending] = React.useState(false);
  const handleApiError = useApiErrorHandler()[1];
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {snapshots.map((snapshot) => {
        const report = snapshot.snapshot_report;
        const onActiveRadioButtonChange = async (isChecked: boolean) => {
          if (!isChecked) return;

          setIsPending(true);
          try {
            const response = await authenticatedApiService.snapshot(snapshot.id).activate();
            if (isSuccessful(response)) {
              onActiveChanged();
            } else {
              handleApiError(response);
            }
          } finally {
            setIsPending(false);
          }
        };

        const deleteSnapshot = async () => {
          setIsPending(true);
          try {
            const response = await authenticatedApiService.snapshot(snapshot.id).delete();
            if (isSuccessful(response)) {
              onActiveChanged();
            } else {
              handleApiError(response);
            }
          } finally {
            setIsPending(false);
          }
        };

        const onDropdownSelection = (value: DropdownSelection) => {
          switch (value) {
            case DropdownSelection.load:
              navigate(
                ApplicationRoutes.getPath("realtySnapshotDetail", {
                  realtySnapshotId: snapshot.id.toString(),
                  realtyId: realtyId.toString(),
                }),
              );
              break;
            case DropdownSelection.download:
              navigate(
                ApplicationRoutes.getPath("reportCreated", { reportId: snapshot.snapshot_report.id.toString() }),
              );
              break;
            case DropdownSelection.softDelete:
              window.confirm(t("snapshotList.softDeleteConfirm")) && deleteSnapshot();
              break;
          }
        };

        const disableActiveRadioButton = isPending || isNil(report.id);

        return (
          <ObjectDetailRow
            key={snapshot.id}
            reportAvailable={!isNil(report.id)}
            modelDate={report.MODVERSOUT}
            refNr={snapshot.remms4all_form.REF_NR}
            version={snapshot.remms4all_form.VERSION}
            dataDate={`${report.DATENSTAND_IMMOWERT_TAG}.${report.DATENSTAND_IMMOWERT_MONAT}.${report.DATENSTAND_IMMOWERT_JAHR}`}
            verbrauch={report.VERBRAUCH_GESAMTENERG}
            emission={report.EMISSIONEN_GESAMTENERG}
            capex={formatCurrencyInThousands(t, Number(report.INVEST_KOSTEN_NETTO))}
            isActive={snapshot.active}
            ratings={{
              oekonomieRatingParam: report.GESAMTRAT_OEKONOMIE,
              oekonomieRisikoParam: report.RISKIND_GESMT_OEKON > 0,
              oekologieRatingParam: report.GESAMTRAT_OEKOLOGIE,
              oekologieRisikoParam: report.RISKIND_GESMT_OEKOL > 0,
              sozialRatingParam: report.GESAMTRAT_SOZIALES,
              sozialRisikoParam: report.RISKIND_GESMT_SOZ > 0,
            }}
            activeRadio={{ isDisabled: disableActiveRadioButton, isChecked: snapshot.active }}
            onActiveRadioButtonChange={onActiveRadioButtonChange}
            onDropdownSelection={onDropdownSelection}
            {...({ "data-test-is-active": snapshot.active } as any)}
          />
        );
      })}
    </>
  );
};

export default withAuthenticatedApiService(DashboardSnapshotsList);
