import * as React from "react";
import {
  PlasmicDynamicPowerListItem,
  DefaultDynamicPowerListItemProps,
} from "./plasmic/remms_4_all/PlasmicDynamicPowerListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DynamicPowerListItemProps extends DefaultDynamicPowerListItemProps {}

function DynamicPowerListItem_({ onDelete, ...props }: DynamicPowerListItemProps, ref: HTMLElementRefOf<"div">) {
  return <PlasmicDynamicPowerListItem root={{ ref }} {...props} itemDeleteButton={{ onClick: onDelete }} />;
}

const DynamicPowerListItem = React.forwardRef(DynamicPowerListItem_);
export default DynamicPowerListItem;
