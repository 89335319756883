// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Plasmic_022BerichtSuccessRegistriert,
  Default_022BerichtSuccessRegistriertProps,
} from "./plasmic/remms_4_all/Plasmic_022BerichtSuccessRegistriert";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useParams } from "react-router-dom";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { useInterval } from "usehooks-ts";
import { useState } from "react";
import { isSuccessful } from "../api/apiEndpoint";
import { ReportResponse } from "../api/types";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { formatReportAddress } from "../utils";

export interface _022BerichtSuccessRegistriertProps
  extends Default_022BerichtSuccessRegistriertProps,
    WithAuthenticatedApiServiceProps {}

function _022BerichtSuccessRegistriert_(
  { authenticatedApiService, ...props }: _022BerichtSuccessRegistriertProps,
  ref: HTMLElementRefOf<"div">,
) {
  const { reportId } = useParams();
  const [report, setReport] = useState<ReportResponse | undefined>(undefined);
  const [requestRunning, setRequestRunning] = useState<boolean>(false);
  const handleApiError = useApiErrorHandler()[1];

  const isPending = report === undefined || report.pdf_report_state === "pending";

  useInterval(
    () => {
      (async () => {
        setRequestRunning(true);
        const response = await authenticatedApiService.report(reportId!).get();
        setRequestRunning(false);
        if (isSuccessful(response)) {
          setReport(response.data);
        } else {
          handleApiError(response);
        }
      })();
    },
    isPending && !requestRunning ? 300 : null,
  );

  return (
    <Plasmic_022BerichtSuccessRegistriert
      processHeader={{ address: formatReportAddress(report?.api_request) }}
      reportDownloadButton={{
        "data-testid": "report-download-button",
        isLoading: isPending,
        isError: report?.pdf_report_state === "failed",
        onClick: () => {
          if (report?.pdf_report_state === "done") {
            const url = report.pdf_report_url!;
            window.open(url, "_blank");
          }
        },
      }}
      reportSuccessHeader={{
        refNr: report?.api_request.REF_NR,
        version: report?.api_request.VERSION,
        address: formatReportAddress(report?.api_request, true),
      }}
      root={{ ref }}
      {...props}
    />
  );
}

const _022BerichtSuccessRegistriert = withAuthenticatedApiService(React.forwardRef(_022BerichtSuccessRegistriert_));
export default _022BerichtSuccessRegistriert;
