import * as React from "react";
import {
  PlasmicProcessEsgStickyNav,
  DefaultProcessEsgStickyNavProps,
} from "./plasmic/remms_4_all/PlasmicProcessEsgStickyNav";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ProcessEsgStickyNavProps extends DefaultProcessEsgStickyNavProps {
  isSubmitting: boolean;
}

function ProcessEsgStickyNav_({ isSubmitting, ...props }: ProcessEsgStickyNavProps, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicProcessEsgStickyNav
      root={{ ref }}
      submitButton={{
        isLoading: isSubmitting,
        isDisabled: isSubmitting,
      }}
      {...props}
    />
  );
}

const ProcessEsgStickyNav = React.forwardRef(ProcessEsgStickyNav_);
export default ProcessEsgStickyNav;
