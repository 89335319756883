import * as React from "react";
import { PlasmicBanner, DefaultBannerProps } from "./plasmic/remms_4_all/PlasmicBanner";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface BannerProps extends DefaultBannerProps {}

function Banner_(props: BannerProps, ref: HTMLElementRefOf<"div">) {
  return <PlasmicBanner root={{ ref }} {...props} />;
}

const Banner = React.forwardRef(Banner_);
export default Banner;
