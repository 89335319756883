// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicNavProfileDropdown,
  DefaultNavProfileDropdownProps,
} from "./plasmic/remms_4_all/PlasmicNavProfileDropdown";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import AuthToken from "../api/AuthToken";
import { SelectRefValue } from "@plasmicapp/react-web";
import { switchLanguage } from "../utils";

export interface NavProfileDropdownProps extends DefaultNavProfileDropdownProps, WithAuthenticatedApiServiceProps {}

const enum DropdownSelectionActions {
  italian = 1,
  french = 2,
  german = 3,
  english = 4,
  userSettings = 5,
  userAgreement = 6,
  activatePortfolio = 7,
  infoAboutMyRemmsUse = 8,
  logout = 9,
}

function NavProfileDropdown_(
  { authenticatedApiService, ...props }: NavProfileDropdownProps,
  ref: React.Ref<SelectRefValue>,
) {
  const handleApiError = useApiErrorHandler()[1];
  const navigate = useNavigate();

  const handleDropdownSelection = (value: string) => {
    // TODO This is needed due to type annotation errors from plasmic
    const castedValue: number = value as any;

    switch (castedValue) {
      case DropdownSelectionActions.userSettings:
        navigate(ApplicationRoutes.getPath("userSettings"));
        break;
      case DropdownSelectionActions.userAgreement:
        navigate(ApplicationRoutes.getPath("userSettings") + "#usageAgreementUserSettings");
        break;
      case DropdownSelectionActions.activatePortfolio:
        navigate(ApplicationRoutes.getPath("userSettings") + "#generalUserSettings");
        break;
      case DropdownSelectionActions.logout:
        authenticatedApiService.session.signOut().then((response) => {
          if (isSuccessful(response)) {
            AuthToken.remove();
            navigate(ApplicationRoutes.getPath("login"), { replace: true });
          } else {
            handleApiError(response);
          }
        });
        break;
      case DropdownSelectionActions.italian:
        switchLanguage("it");
        break;
      case DropdownSelectionActions.french:
        switchLanguage("fr");
        break;
      case DropdownSelectionActions.english:
        switchLanguage("en");
        break;
      case DropdownSelectionActions.german:
        switchLanguage("de");
        break;
      default:
        console.error("I do not know what to do with this value");
    }
  };
  return (
    <PlasmicNavProfileDropdown
      {...props}
      onProfileDropdownSelection={(value) => handleDropdownSelection(value)}
      ref={ref}
    />
  );
}

const NavProfileDropdown = withAuthenticatedApiService(React.forwardRef(NavProfileDropdown_), {
  redirectOnIncompleteSignup: false,
});
export default NavProfileDropdown;
