import * as React from "react";
import {
  Default_010ErfassungSimpelProps,
  Plasmic_010ErfassungSimpel,
} from "./plasmic/remms_4_all/Plasmic_010ErfassungSimpel";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext } from "react-hook-form";

export interface _010ErfassungSimpelProps extends Default_010ErfassungSimpelProps {
  isLoggedIn: boolean;
  isSubmitting: boolean;
  onSwitchToComplexClick: (event: any) => void;
}

function _010ErfassungSimpel_(
  { isLoggedIn, isSubmitting, onSwitchToComplexClick, ...props }: _010ErfassungSimpelProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [isSwitching, setIsSwitching] = React.useState(false);

  const { getValues } = useFormContext();
  const address = getValues("address");

  const switchToComplex = (event: any) => {
    setIsSwitching(true);
    // To actually render the isSwitchting state we need to call onSwitchToComplexClick later
    setTimeout(() => {
      onSwitchToComplexClick(event);
    });
  };

  return (
    <Plasmic_010ErfassungSimpel
      root={{ ref }}
      processHeader={{ isLoggedIn, address: address }}
      submitButton={{ isLoading: isSubmitting, isDisabled: isSubmitting }}
      processStickyNav={{ isSubmitting }}
      processPageActions={{
        onSwitchToComplexClick: switchToComplex,
        switchToComplexButton: {
          isDisabled: isSwitching,
          isLoading: isSwitching,
        },
      }}
      {...props}
    />
  );
}

const _010ErfassungSimpel = React.forwardRef(_010ErfassungSimpel_);
export default _010ErfassungSimpel;
