import * as React from "react";
import { Plasmic_000Login, Default_000LoginProps } from "./plasmic/remms_4_all/Plasmic_000Login";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { SignInRequest, SignInResponse } from "../api/types";
import { PublicApiService } from "../api/PublicApiService";
import { isSuccessful, type SuccessfulResponse } from "../api/apiEndpoint";
import { Navigate, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import AuthToken from "../api/AuthToken";
import { useApiErrorHandler } from "../useApiErrorHandler";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface _000LoginProps extends Default_000LoginProps {}

function _000Login_(props: _000LoginProps, ref: HTMLElementRefOf<"div">) {
  const navigate = useNavigate();
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const { t } = useTranslation();

  if (AuthToken.get()?.isExpired() === false) {
    return <Navigate to={ApplicationRoutes.getPath("root")} replace={true} />;
  }

  const processSuccessfulResponse = (response: SuccessfulResponse<SignInResponse>) => {
    const token = response.headers.get("Authorization");
    if (!token) {
      toast.error(t("toast.unexpected_error_try_again"));
      return;
    }

    AuthToken.set(token);
    navigate(ApplicationRoutes.getPath("root"));
  };

  const onSubmit = async (values: SignInRequest) => {
    const response = await PublicApiService.session.signIn(values);

    if (isSuccessful(response)) {
      processSuccessfulResponse(response);
    } else {
      handleApiError(response);
    }
  };

  return (
    <FormWrapper<SignInRequest> className="login-form-wrapper" onSubmit={onSubmit} apiErrors={apiErrors}>
      <Plasmic_000Login {...props} ref={ref} />
    </FormWrapper>
  );
}

const _000Login = React.forwardRef(_000Login_);
export default _000Login;
