import { ApplicationRoutes, NamedRoute } from "./ApplicationRoutes";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import AuthToken from "./api/AuthToken";

export const useEnsureGuest = (fallbackRoute: NamedRoute = "root") => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const authToken = AuthToken.get();
    if (authToken && !authToken.isExpired()) {
      navigate(ApplicationRoutes.getPath(fallbackRoute));
    }
  }, [navigate, fallbackRoute]);
};
