import _022BerichtSuccessNichtRegisriert from "./_022BerichtSuccessNichtRegisriert";
import React from "react";
import { Navigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { GUEST_REALTY_REPORT_CREATED_STORAGE_KEY, GuestRealtyCreationResponse } from "../api/types";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isNil } from "lodash";

const GuestReportCreated = () => {
  const [report] = useSessionStorage<GuestRealtyCreationResponse | null>(GUEST_REALTY_REPORT_CREATED_STORAGE_KEY, null);

  if (!isNil(report)) {
    return <_022BerichtSuccessNichtRegisriert report={report} />;
  } else {
    return <Navigate to={ApplicationRoutes.getPath("guestRealtyNewAddress")} replace={true} />;
  }
};

export default GuestReportCreated;
