import React from "react";
import { compact, negate, overSome, isNil, isObjectLike, isEmpty } from "lodash";
import { DefaultButtonProps, PlasmicButton } from "./components/plasmic/remms_4_all/PlasmicButton";
import { JSONSerializable } from "./api/apiEndpoint";
import { TFunction } from "i18next";
import { DefaultTermsOfUseForm, Locale, RealtyAddress, TermsOfUse, TermsOfUseForm } from "./api/types";

export function getValOfPlasmicButton(child: React.ReactElement<DefaultButtonProps, typeof PlasmicButton>) {
  try {
    if (typeof child.props.value == "string") return child.props.value;
    if (typeof child.props.children == "string") return child.props.children;
  } catch (_e) {
    return undefined;
  }
}

export function addLocaleToLink(link: string, locale: string) {
  if (link.startsWith("/")) {
    if (!link.startsWith(`/${locale}/`)) {
      link = `/${locale}${link}`;
    }
  }
  return link;
}

export const isPresent = negate(
  overSome<string | number>(
    isNil,
    (v) => (typeof v === "number" ? isNaN(v) : false),
    (v) => (typeof v === "string" ? v.trim() === "" : false),
    (v) => isObjectLike(v) && isEmpty(v),
  ),
);

/**
 * Returns the textual value of a component used in Plasmic up to the first child.
 * Can be used to get the text value of a button, for example.
 */
export function getValOfPlasmicComponent(child: React.ReactNode) {
  if (typeof child === "string") return child;
  if (!React.isValidElement(child)) return undefined;

  try {
    if (typeof child.props.value == "string") return child.props.value;
    if (typeof child.props.text == "string") return child.props.text;
    if (typeof child.props.children == "string" && !isEmpty(child.props.children)) return child.props.children;
    if (typeof (child.props.text as React.ReactElement).props.children == "string") {
      return (child.props.text as React.ReactElement).props.children;
    }
  } catch (_e) {
    return undefined;
  }
}

export const RenderControlContext = React.createContext({ hidden: false });

export const ensureValueOrNull = (val: keyof JSONSerializable) => {
  return val === undefined || val === "" ? null : val;
};

export function scrollElementIntoView(elementId: string) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    window.location.hash = elementId;
  }
}

export function ensureValidEnumValue<
  T extends object,
  D extends T[keyof T] | undefined,
  R extends D extends undefined ? D : T[keyof T],
>(value: unknown, enumType: T, defaultValue: D = undefined as D): R {
  if (Object.values(enumType).includes(value)) {
    return value as R;
  }

  return defaultValue as unknown as R;
}

export function formatCurrencyInThousands(t: TFunction, amount?: number) {
  if (amount === undefined || isNaN(amount)) {
    return "--";
  }

  const formatter = new Intl.NumberFormat("de-CH", {
    style: "currency",
    currency: "CHF",
    currencyDisplay: "code",
    unitDisplay: "short",
    maximumFractionDigits: 0,
  });

  return formatter.format(amount).replace("CHF", t("tCHF"));
}

export function formatRatingNumber(number?: number | string) {
  if (number === undefined || number === null) return "--";

  const parsed = Number(number);
  if (isNaN(parsed)) return "--";

  return parsed.toFixed(1);
}

export const formatDate = (dateString?: string) => {
  if (isNil(dateString)) return "--.--.--";

  const date = new Date(dateString);
  return date.toLocaleDateString("de-CH");
};

export const formatNumberWithApostrophes = (number?: string | number, decimalPlaces = 0) => {
  if (isNil(number)) return "--";

  const formattedNumber = typeof number === "string" ? parseFloat(number) : number;

  const formatter = new Intl.NumberFormat("de-CH", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  return formatter.format(formattedNumber);
};

export const formatDecimalToPercent = (number?: number | null) => {
  if (isNil(number)) return "--";

  return `${Math.round(number * 100)}%`;
};

export function formatAddress(address: RealtyAddress, multiline = false) {
  const street = compact([address.BUILDING_STREET, address.BUILDING_NUMBER]).join(" ");
  const city = compact([address.BUILDING_ZIPCODE, address.BUILDING_CITY]).join(" ");
  return compact([street, city]).join(multiline ? "\n" : ", ");
}

export function formatReportAddress(address?: RealtyAddress, multiline = false) {
  if (address) {
    return formatAddress(address, multiline);
  }

  return multiline ? "\n\n" : "\n";
}

export function patchTermsOufUseFormData(termsOfUseForm: TermsOfUseForm): TermsOfUse {
  const { data_usage, ...other } = termsOfUseForm;
  const termsOfUse: TermsOfUse = {
    ...other,
    NVB_EV_DS_INKL_ADR: data_usage === "NVB_EV_DS_INKL_ADR",
    NVB_EV_DS_EXKL_ADR: data_usage === "NVB_EV_DS_EXKL_ADR",
    NVB_EV_DV_BM: data_usage === "NVB_EV_DV_BM",
    NVB_EV_DURCHL_PLZ: data_usage === "NVB_EV_DURCHL_PLZ",
  };
  return termsOfUse;
}

export function extractTermsOfUse(termsOfUse?: TermsOfUse): TermsOfUseForm {
  if (!termsOfUse) {
    return DefaultTermsOfUseForm;
  }
  const { NVB_EV_DS_INKL_ADR, NVB_EV_DS_EXKL_ADR, NVB_EV_DV_BM, NVB_EV_DURCHL_PLZ, ...other } = termsOfUse;
  let data_usage = "";
  if (NVB_EV_DS_INKL_ADR) {
    data_usage = "NVB_EV_DS_INKL_ADR";
  } else if (NVB_EV_DS_EXKL_ADR) {
    data_usage = "NVB_EV_DS_EXKL_ADR";
  } else if (NVB_EV_DV_BM) {
    data_usage = "NVB_EV_DV_BM";
  } else if (NVB_EV_DURCHL_PLZ) {
    data_usage = "NVB_EV_DURCHL_PLZ";
  }
  const termsOfUseForm: TermsOfUseForm = {
    ...other,
    data_usage,
  };
  return termsOfUseForm;
}

export function switchLanguage(targetLocale: Locale) {
  const pathname = window.location.pathname.replace(/^\/../, targetLocale);
  window.location.pathname = pathname;
}
