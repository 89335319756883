import React from "react";
import { useSearchParams } from "react-router-dom";
import { ensureValidEnumValue } from "./utils";
import { RealtyFormType } from "./api/types";

const useRealtyFormAttributes = () => {
  const [params] = useSearchParams();

  return React.useMemo(() => {
    return {
      initialFormType: ensureValidEnumValue(Number(params.get("formType")), RealtyFormType, RealtyFormType.simple),
      GEBARTC: Number(params.get("GEBARTC")) || undefined,
      addressString: params.get("address") || "",
    };
  }, [params]);
};

export default useRealtyFormAttributes;
