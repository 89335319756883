// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LogoutIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LogoutIcon(props: LogoutIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g className={"nc-icon-wrapper"} fill={"currentColor"}>
        <path
          d={
            "M11 14H3V2h8a1 1 0 000-2H3a2 2 0 00-2 2v12a2 2 0 002 2h8a1 1 0 000-2z"
          }
          fill={"currentColor"}
        ></path>

        <path
          data-color={"color-2"}
          d={
            "M14.707 7.293l-3-3a1 1 0 00-1.414 1.414L11.586 7H6a1 1 0 000 2h5.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default LogoutIcon;
/* prettier-ignore-end */
