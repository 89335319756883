import * as React from "react";
import { PlasmicPortfolioModal, DefaultPortfolioModalProps } from "./plasmic/remms_4_all/PlasmicPortfolioModal";
import FormWrapper from "./custom/FormWrapper";
import { isSuccessful } from "../api/apiEndpoint";
import { AuthenticatedApiService } from "../api/AuthenticatedApiService";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { PortfolioForm, ESGQuestionnaireForm } from "../api/types";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface PortfolioModalProps extends DefaultPortfolioModalProps {
  esgQuestionnaire: ESGQuestionnaireForm;
  authenticatedApiService: AuthenticatedApiService;
  onClose: () => void;
  onPortfolioCreationStart: (toastId: string) => void;
}

const prepareFormData = (formData: PortfolioForm): PortfolioForm => {
  const coverageAsPercentage = formData.coverage;
  return {
    ...formData,
    // empty string and null will be treated as undefined instead of converted to 0
    coverage: coverageAsPercentage ? Number(coverageAsPercentage) / 100 : undefined,
  };
};

function PortfolioModal(
  {
    authenticatedApiService,
    esgQuestionnaire,
    onPortfolioCreationStart,
    className,
    onClose,
    ...props
  }: PortfolioModalProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const defaultValues = {
    realty_data_referral_date: new Date().toISOString().split("T")[0] as `${number}-${number}-${number}`,
    energy_data_referral_date: esgQuestionnaire?.energyDataReferralDate as `${number}-${number}-${number}`,
    coverage: ((esgQuestionnaire?.NVB_ABDKG_DEKL as number) ?? 0) * 100,
    name: esgQuestionnaire?.PF_VERSION as string,
  };

  const onSubmit = React.useCallback(
    async (formData: PortfolioForm) => {
      setIsSubmitting(true);

      const response = await authenticatedApiService.portfolioVersions.post(prepareFormData(formData));

      if (isSuccessful(response)) {
        // We still provide an auto-dismissing duration in case the user navigates away or something else goes wrong
        // so that the toast doesn't stay forever
        const toastId = toast.loading(t("portfolioModal.createConfirm"), { duration: 60_000 });
        onClose();
        onPortfolioCreationStart(toastId);
      } else {
        handleApiError(response);
      }

      setIsSubmitting(false);
    },
    [authenticatedApiService, t, onClose, onPortfolioCreationStart, handleApiError],
  );

  return (
    <FormWrapper defaultValues={defaultValues} className={className} onSubmit={onSubmit} apiErrors={apiErrors}>
      <PlasmicPortfolioModal
        {...props}
        onClose={onClose}
        button={{ isDisabled: isSubmitting, isLoading: isSubmitting }}
      />
    </FormWrapper>
  );
}

export default PortfolioModal;
