import * as React from "react";
import {
  PlasmicMultiSelectButtonGroup,
  DefaultMultiSelectButtonGroupProps,
} from "./plasmic/remms_4_all/PlasmicMultiSelectButtonGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import PlasmicButton, { DefaultButtonProps } from "./plasmic/remms_4_all/PlasmicButton";
import { getValOfPlasmicComponent } from "../utils";

function cloneSet<T>(set: Iterable<T>, operation: (clone: Set<T>) => void): Set<T> {
  const clone = new Set(set);
  operation(clone);
  return clone;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isIterable = (obj: any): obj is Iterable<any> => Symbol.iterator in Object(obj);

type ButtonValue = React.ComponentProps<"button">["value"];
type ButtonsValueSet = Set<ButtonValue>;

export interface MultiSelectButtonGroupProps extends DefaultMultiSelectButtonGroupProps {
  children?: Iterable<React.ReactElement<DefaultButtonProps, typeof PlasmicButton>>;
  value?: ButtonValue[];
  onChange?: (values: ButtonValue[]) => void;
}

function MultiSelectButtonGroup_(
  { children, value, onChange, ...rest }: MultiSelectButtonGroupProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [activeButtons, setActiveButtons] = React.useState<ButtonsValueSet>(
    isIterable(value) ? new Set([...value]) : new Set(),
  );

  React.useEffect(() => void (onChange && onChange(Array.from(activeButtons))), [activeButtons, onChange]);

  if (!children) return <PlasmicMultiSelectButtonGroup root={{ ref }} {...rest} />;

  const modifiedChildren = Array.from(children).map((child, index) => {
    const val = getValOfPlasmicComponent(child);

    return React.cloneElement(child, {
      onClick: () =>
        setActiveButtons(cloneSet(activeButtons, (clone) => (clone.has(val) ? clone.delete(val) : clone.add(val)))),
      buttonType: activeButtons.has(val) ? "solid" : "soft",
      submitsForm: false,
      type: "button", // prevent form submission, always
      key: index,
    });
  });

  return (
    <PlasmicMultiSelectButtonGroup root={{ ref }} {...rest}>
      {modifiedChildren}
    </PlasmicMultiSelectButtonGroup>
  );
}

const MultiSelectButtonGroup = React.forwardRef(MultiSelectButtonGroup_);
export default MultiSelectButtonGroup;
