import * as React from "react";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import _008ErfassungAdresse from "./_008ErfassungAdresse";
import { AddressSearch, RealtyAddress, RealtyFormType } from "../api/types";

export enum RealtyTypeSwitchValue {
  singleFamilyHouse = 1,
  other = 2,
}

export interface NewRealtyAddressForm {
  realtyAddress: AddressSearch["DetailsResponse"]; // TODO: use RealtyAddress instead
  realtyType: RealtyTypeSwitchValue;
  formType: RealtyFormType;
}

export type NewRealtyAddressParams = {
  address: RealtyAddress;
  formType: `${RealtyFormType.simple}` | `${RealtyFormType.detailed}`;
  GEBARTC: "1" | "";
};

export interface NewRealtyAddressProps {
  onSubmit: (queryParams: NewRealtyAddressParams) => void;
  isLoggedIn: boolean;
}

function NewRealtyAddress_({ onSubmit, isLoggedIn }: NewRealtyAddressProps, ref: HTMLElementRefOf<"div">) {
  const handleSubmission = React.useCallback(
    async ({ realtyAddress, realtyType, formType }: NewRealtyAddressForm) => {
      const address: RealtyAddress = {
        BUILDING_STREET: realtyAddress.route,
        BUILDING_NUMBER: realtyAddress.street_number,
        BUILDING_ZIPCODE: realtyAddress.postal_code,
        BUILDING_CITY: realtyAddress.locality,
        BUILDING_COUNTRY: 1, // TODO: always Switzerland for now
      };

      const params: NewRealtyAddressParams = {
        address,
        formType: (formType === RealtyFormType.simple
          ? RealtyFormType.simple
          : RealtyFormType.detailed
        ).toString() as NewRealtyAddressParams["formType"],
        GEBARTC: realtyType === RealtyTypeSwitchValue.singleFamilyHouse ? "1" : "",
      };

      onSubmit(params);
    },
    [onSubmit],
  );

  return (
    <FormWrapper<NewRealtyAddressForm> className="new_realty" onSubmit={handleSubmission}>
      <_008ErfassungAdresse isLoggedIn={isLoggedIn} ref={ref} />
    </FormWrapper>
  );
}

export default React.forwardRef(NewRealtyAddress_);
