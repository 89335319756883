import * as React from "react";
import {
  PlasmicPortfolioDetailRow,
  DefaultPortfolioDetailRowProps,
} from "./plasmic/remms_4_all/PlasmicPortfolioDetailRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { PortfolioSnapshotListEntry } from "../api/types";

export interface PortfolioDetailRowProps extends DefaultPortfolioDetailRowProps {
  snapshot: PortfolioSnapshotListEntry;
}

function PortfolioDetailRow_({ snapshot, ...props }: PortfolioDetailRowProps, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicPortfolioDetailRow
      root={{ ref }}
      {...snapshot}
      pdfButton={{
        target: "_blank",
        isDisabled: !snapshot?.snapshotReportLink,
      }}
      {...props}
      hasReferenceNr={!!snapshot?.refNr}
      hasVersion={!!snapshot?.version}
    />
  );
}

const PortfolioDetailRow = React.forwardRef(PortfolioDetailRow_);
export default PortfolioDetailRow;
