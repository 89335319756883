import { PlasmicCanvasHost } from "@plasmicapp/react-web/lib/host";
import React from "react";
import { registerFormWrapperComponent } from "../components/custom/FormWrapper";
import { registerWiredFormField } from "../components/custom/WiredFormField";
import { registerSliderComponent } from "../components/custom/SliderBase";

function PlasmicHost() {
  const [canvasHost, setCanvasHost] = React.useState<React.ReactNode>(null);
  React.useEffect(() => {
    registerFormWrapperComponent();
    registerWiredFormField();
    registerSliderComponent();

    setCanvasHost(<PlasmicCanvasHost />);
  }, []);

  return React.isValidElement(canvasHost) ? canvasHost : <React.Fragment />;
}

export default PlasmicHost;
