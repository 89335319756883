import {
  ApiResponse,
  ApplicationErrorResponse,
  isApplicationError,
  isAuthenticationError,
  isRequestLimitError,
  isValidationError,
} from "./api/apiEndpoint";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AuthToken from "./api/AuthToken";
import { ApplicationRoutes } from "./ApplicationRoutes";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

// When using nested attributes, the field key has the format
// [nested_attribute]_attributes.field_name
function formatApiErrors(errors: Record<string, string>): Record<string, string> {
  const formattedErrors: Record<string, string> = {};

  for (const [key, value] of Object.entries(errors)) {
    const newKey = key.replace(/\./, "_attributes.");
    formattedErrors[newKey] = value;
  }

  return formattedErrors;
}

function handleApplicationErrors(response: ApplicationErrorResponse): void {
  response.applicationErrors?.forEach((error) => {
    toast.error(error);
  });
}

/**
 * Returns a tuple containing the formatted API validation errors
 * and a function to handle unsuccessful API responses.
 *
 * @returns A tuple `(apiErrors, handleApiError)`
 */
export function useApiErrorHandler<T>(): [Record<string, string>, (response: ApiResponse<T>) => void] {
  const [apiErrors, setApiErrors] = React.useState<Record<string, string>>({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleApiError = React.useCallback(
    (response: ApiResponse<T>) => {
      if (isAuthenticationError(response)) {
        AuthToken.remove();
        navigate(ApplicationRoutes.getPath("login"), { replace: true });
        if (isApplicationError(response)) handleApplicationErrors(response);
      } else if (isValidationError(response)) {
        setApiErrors(formatApiErrors(response.validationErrors));
      } else if (isRequestLimitError(response)) {
        toast.error(t("toast.too_many_requests"));
      } else if (isApplicationError(response)) {
        handleApplicationErrors(response);
      } else {
        if (response.exception) {
          console.error(response.exception);
        } else {
          console.error(response);
        }
        toast.error(t("toast.unexpected_error"));
      }
    },
    [navigate, t],
  );

  return [apiErrors, handleApiError];
}
