import * as React from "react";
import { isNil } from "lodash";
import { PlasmicDashboardEntry, DefaultDashboardEntryProps } from "./plasmic/remms_4_all/PlasmicDashboardEntry";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { ApiResponse, isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { RealtyListEntry, RealtySnapshotResponse } from "../api/types";
import { useNavigate } from "react-router-dom";
import { formatAddress, formatCurrencyInThousands, formatRatingNumber } from "../utils";
import DashboardSnapshotsList from "./custom/DashboardSnapshotsList";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useTranslation } from "react-i18next";

const enum DropdownSelectionActions {
  download = 1,
  softDelete = 2,
  archive = 3,
}

export interface DashboardEntryProps extends DefaultDashboardEntryProps, WithAuthenticatedApiServiceProps {
  realty: RealtyListEntry;
  onListChange: () => void;
}

function formatDataDate({
  active_snapshot: {
    snapshot_report: { DATENSTAND_IMMOWERT_JAHR, DATENSTAND_IMMOWERT_MONAT, DATENSTAND_IMMOWERT_TAG },
  },
}: RealtyListEntry) {
  return `${DATENSTAND_IMMOWERT_TAG}.${DATENSTAND_IMMOWERT_MONAT}.${DATENSTAND_IMMOWERT_JAHR}`;
}

function formatRealtyAddress(realty: RealtyListEntry) {
  return formatAddress(realty.address, true);
}

function DashboardEntry_(
  { authenticatedApiService, realty, onListChange, ...props }: DashboardEntryProps,
  ref: HTMLElementRefOf<"div">,
) {
  const {
    active_snapshot: { snapshot_report, remms4all_form },
  } = realty;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [snapshots, setSnapshots] = React.useState<RealtySnapshotResponse[]>([]);
  const [fetched, setFetched] = React.useState(false);

  const navigate = useNavigate();
  const handleApiError = useApiErrorHandler()[1];
  const { t } = useTranslation();

  async function fetchSnapshots() {
    const response = await authenticatedApiService.dashboard.realty(realty.id).listSnapshots();

    if (isSuccessful(response)) {
      setSnapshots(response.data);
      setFetched(true);
    } else {
      handleApiError(response);
    }
  }

  const handleCollapse = () => {
    if (!fetched) {
      fetchSnapshots().then(() => setIsExpanded(!isExpanded));
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  const softDelete = () => {
    authenticatedApiService
      .realty(realty.id)
      .delete()
      .then((response: ApiResponse<undefined>) => {
        if (isSuccessful(response)) {
          onListChange();
        } else {
          handleApiError(response);
        }
      });
  };

  const archiveRealty = () => {
    authenticatedApiService
      .realty(realty.id)
      .toggle_archive()
      .then((response: ApiResponse<undefined>) => {
        if (isSuccessful(response)) {
          onListChange();
        } else {
          handleApiError(response);
        }
      });
  };

  return (
    <PlasmicDashboardEntry
      root={{ ref }}
      {...props}
      collapseButton={{ onClick: () => handleCollapse() }}
      isExpanded={isExpanded}
      dataDate={formatDataDate(realty)}
      address={formatRealtyAddress(realty)}
      editLink={
        realty.active_snapshot.id
          ? ApplicationRoutes.getPath("realtySnapshotDetail", {
              realtyId: realty.id,
              realtySnapshotId: realty.active_snapshot.id.toString(),
            })
          : ""
      }
      isDraft={!realty.active_snapshot.active}
      modelDate={snapshot_report.MODVERSOUT}
      refNr={remms4all_form.REF_NR}
      hasReferenceNr={!!remms4all_form.REF_NR}
      version={remms4all_form.VERSION}
      hasVersion={!!remms4all_form.VERSION}
      verbrauch={snapshot_report.VERBRAUCH_GESAMTENERG}
      emission={snapshot_report.EMISSIONEN_GESAMTENERG}
      capex={formatCurrencyInThousands(t, Number(snapshot_report.INVEST_KOSTEN_NETTO))}
      sozialRating={{
        ratingNumber: formatRatingNumber(snapshot_report.GESAMTRAT_SOZIALES),
        hasWarning: snapshot_report.RISKIND_GESMT_SOZ > 0,
      }}
      oekonomieRating={{
        ratingNumber: formatRatingNumber(snapshot_report.GESAMTRAT_OEKONOMIE),
        hasWarning: snapshot_report.RISKIND_GESMT_OEKON > 0,
      }}
      oekologieRating={{
        ratingNumber: formatRatingNumber(snapshot_report.GESAMTRAT_OEKOLOGIE),
        hasWarning: snapshot_report.RISKIND_GESMT_OEKOL > 0,
      }}
      onEditClick={(event) => {
        event.preventDefault();
        navigate(
          ApplicationRoutes.getPath("realtySnapshotDetail", {
            realtyId: realty.id,
            realtySnapshotId: realty.active_snapshot.id.toString(),
          }),
        );
      }}
      reportAvailable={!isNil(realty.active_snapshot.snapshot_report.id)}
      onDropdownSelection={(value) => {
        // TODO This is needed due to type annotation errors from plasmic
        const castedValue: number = value as unknown as DropdownSelectionActions;
        switch (castedValue) {
          case DropdownSelectionActions.download:
            navigate(
              ApplicationRoutes.getPath("reportCreated", {
                reportId: realty.active_snapshot.snapshot_report.id.toString(),
              }),
            );
            break;
          case DropdownSelectionActions.softDelete:
            if (window.confirm(t("dashboardEntry.softDeleteConfirm"))) {
              softDelete();
            }
            break;
          case DropdownSelectionActions.archive:
            archiveRealty();
            break;
          default:
            console.error("I do not know what to do with this value");
        }
      }}
      snapshotList={{
        children: (
          <DashboardSnapshotsList
            snapshots={snapshots}
            realtyId={Number(realty.id)}
            onActiveChanged={() => {
              void fetchSnapshots();
              onListChange();
            }}
          />
        ),
      }}
    />
  );
}

const DashboardEntry = withAuthenticatedApiService(React.forwardRef(DashboardEntry_));
export default DashboardEntry;
