import * as React from "react";
import { PlasmicButton, DefaultButtonProps } from "./plasmic/remms_4_all/PlasmicButton";
import { ButtonRef, HtmlAnchorOnlyProps, HtmlButtonOnlyProps } from "@plasmicapp/react-web";
import { useNavigate } from "react-router-dom";

function isFullURL(url?: string) {
  return url && (url.startsWith("http://") || url.startsWith("https://"));
}

export interface ButtonProps extends Omit<DefaultButtonProps, "target"> {
  // Feel free to add any additional props that this component should receive
  target: HTMLAnchorElement["target"]; // override the default target prop to avoid type errors when using `useBehavior`
}

function Button_(props: ButtonProps, ref: ButtonRef) {
  const { plasmicProps } = PlasmicButton.useBehavior<ButtonProps>(props, ref);
  const navigate = useNavigate();

  if (props.link && props.onClick === undefined && !isFullURL(props.link)) {
    return (
      <PlasmicButton
        onClick={(event) => {
          event.preventDefault();
          props.link && navigate(props.link);
        }}
        {...plasmicProps}
      />
    );
  } else {
    return <PlasmicButton {...plasmicProps} />;
  }
}

export type ButtonComponentType = {
  (
    props: Omit<ButtonProps, HtmlAnchorOnlyProps> & {
      ref?: React.Ref<HTMLButtonElement>;
    },
  ): React.ReactElement;
  (
    props: Omit<ButtonProps, HtmlButtonOnlyProps> & {
      ref?: React.Ref<HTMLAnchorElement>;
    },
  ): React.ReactElement;
};
const Button = React.forwardRef(Button_) as any as ButtonComponentType;

export default Object.assign(Button, {
  __plumeType: "button",
});
