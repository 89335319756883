// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PinIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PinIcon(props: PinIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8 0C5.067 0 2 2.267 2 6c0 3.533 5.333 8.933 5.533 9.133.134.134.267.2.467.2s.333-.066.467-.2C8.667 14.933 14 9.533 14 6c0-3.733-3.067-6-6-6zm0 8c-1.133 0-2-.867-2-2s.867-2 2-2 2 .867 2 2-.867 2-2 2z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default PinIcon;
/* prettier-ignore-end */
