import * as React from "react";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { createSearchParams, useNavigate } from "react-router-dom";
import NewRealtyAddress, { NewRealtyAddressParams } from "./NewRealtyAddress";

function AuthenticatedNewRealtyAddress_(_props: WithAuthenticatedApiServiceProps, ref: HTMLElementRefOf<"div">) {
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (params: NewRealtyAddressParams) => {
      const queryParams = createSearchParams({
        ...params,
        address: btoa(JSON.stringify(params.address)),
      });

      navigate({
        pathname: ApplicationRoutes.getPath("authenticatedRealtyNewForm"),
        search: queryParams.toString(),
      });
    },
    [navigate],
  );

  return <NewRealtyAddress isLoggedIn={true} onSubmit={onSubmit} ref={ref} />;
}

// Wrapping the component with withAuthenticatedApiService will ensure that the user is logged in
// before rendering the component (even though we do not use the injected props in this component).
const AuthenticatedNewRealtyAddress = withAuthenticatedApiService(React.forwardRef(AuthenticatedNewRealtyAddress_));
export default AuthenticatedNewRealtyAddress;
