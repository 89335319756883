// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicUsageAgreementUserSettings,
  DefaultUsageAgreementUserSettingsProps,
} from "./plasmic/remms_4_all/PlasmicUsageAgreementUserSettings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useTranslation } from "react-i18next";
import { isSuccessful } from "../api/apiEndpoint";
import toast from "react-hot-toast";
import { TermsOfUse, TermsOfUseForm } from "../api/types";
import { extractTermsOfUse, patchTermsOufUseFormData } from "../utils";

export interface UsageAgreementUserSettingsProps
  extends DefaultUsageAgreementUserSettingsProps,
    WithAuthenticatedApiServiceProps {
  termsOfUse?: TermsOfUse;
}

function UsageAgreementUserSettings_(
  { termsOfUse, authenticatedApiService, ...props }: UsageAgreementUserSettingsProps,
  ref: HTMLElementRefOf<"div">,
) {
  const handleApiErrors = useApiErrorHandler()[1];
  const { t } = useTranslation();

  const defaultValues = {
    terms_of_use: extractTermsOfUse(termsOfUse),
  };

  const onSubmit = async (data: { terms_of_use: TermsOfUseForm }) => {
    const terms_of_use = patchTermsOufUseFormData(data.terms_of_use);
    const response = await authenticatedApiService.currentUser.update({ terms_of_use });

    if (isSuccessful(response)) {
      toast.success(t("toast.user_agreement_updated"));
    } else {
      handleApiErrors(response);
    }
  };

  return (
    <FormWrapper defaultValues={defaultValues} className={props.className} onSubmit={onSubmit}>
      <PlasmicUsageAgreementUserSettings root={{ ref }} {...props} />
    </FormWrapper>
  );
}

const UsageAgreementUserSettings = withAuthenticatedApiService(React.forwardRef(UsageAgreementUserSettings_));
export default UsageAgreementUserSettings;
