import React from "react";
import { LoaderIcon } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const LoadingScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100vw", height: "100vh", background: "#0f2e29" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <LoaderIcon />
        <span style={{ marginLeft: ".5em" }}>{t("loading")}</span>
      </div>
    </div>
  );
};

export default LoadingScreen;
