import * as React from "react";
import FormWrapper from "./custom/FormWrapper";
import { RealtyAddress, RealtyFormType, Remms4allForm } from "../api/types";
import _010ErfassungSimpel from "./_010ErfassungSimpel";
import { patchFormData } from "../api/formPatch";
import { formatAddress } from "../utils";
import _010Erfassung from "./_010Erfassung";

export interface NewRealtyFormProps {
  isLoggedIn: boolean;
  isSubmitting: boolean;
  onSubmit: (formData: Remms4allForm, address: RealtyAddress) => Promise<void>;
  addressString: string;
  GEBARTC: number | undefined;
  apiErrors?: Record<string, string>;
  initialFormType: RealtyFormType;
  hasSaveButton: boolean;
  onSaveClick?: (formData: Remms4allForm, address: RealtyAddress) => Promise<void>;
}

function NewRealtyForm_({
  onSubmit,
  addressString,
  GEBARTC,
  apiErrors,
  initialFormType,
  onSaveClick,
  ...props
}: NewRealtyFormProps) {
  const address = React.useMemo<RealtyAddress>(() => JSON.parse(atob(addressString)), [addressString]);

  const [formType, setFormType] = React.useState(initialFormType);

  const submissionHandler = async (formData: Remms4allForm) => {
    patchFormData(formData);
    formData.R4A_GUI_DET = formType;
    await onSubmit(formData, address);
  };

  const onSaveHandler = async (formData: Remms4allForm) => {
    if (onSaveClick) {
      patchFormData(formData);
      formData.R4A_GUI_DET = formType;
      await onSaveClick(formData, address);
    }
  };

  return (
    <FormWrapper<Remms4allForm>
      className="erfassung-full"
      onSubmit={submissionHandler}
      defaultValues={{
        GEBARTC,
        R4A_GUI_DET: initialFormType,
        detailedReport: true,
        address: formatAddress(address),
      }}
      apiErrors={apiErrors}
    >
      {formType === RealtyFormType.simple ? (
        <_010ErfassungSimpel onSwitchToComplexClick={() => setFormType(RealtyFormType.detailed)} {...props} />
      ) : (
        <_010Erfassung onSaveClick={onSaveHandler} {...props} />
      )}
    </FormWrapper>
  );
}

export default NewRealtyForm_;
