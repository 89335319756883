import React, { PropsWithChildren, useEffect } from "react";
import { forIn } from "lodash";
import { useFormContext } from "react-hook-form";

export interface FormApiErrorHandlerProps {
  apiErrors?: Record<string, string>;
}

function FormApiErrorHandler({ children, apiErrors }: PropsWithChildren<FormApiErrorHandlerProps>) {
  const formMethods = useFormContext();
  const { setError } = formMethods;

  useEffect(() => {
    if (!apiErrors) {
      return;
    }
    let firstError = true;
    forIn(apiErrors, (message, key) => {
      const element = document.getElementsByName(key)[0];
      if (element) {
        setError(key, {
          type: "manual",
          message: message,
        });
        if (firstError) {
          firstError = false;
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      } else {
        console.error("Error on field'", key, "'which isn't found:", message);
      }
    });
  }, [setError, apiErrors]);

  return <>{children}</>;
}

export default FormApiErrorHandler;
