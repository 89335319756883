// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LargeHomeHeartIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LargeHomeHeartIcon(props: LargeHomeHeartIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 64 64"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        className={"nc-icon-wrapper"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeWidth={"1"}
        fill={"none"}
        stroke={"currentColor"}
      >
        <path data-cap={"butt"} d={"M2.5 28.5l30-24 30 24"}></path>

        <path d={"M10.5 30.5v30h44v-30"}></path>

        <path
          d={
            "M42.583 30.484A6.412 6.412 0 0032.5 31.53a6.413 6.413 0 00-10.083-1.046 6.943 6.943 0 000 9.58L32.5 50.5l10.083-10.436a6.943 6.943 0 000-9.58z"
          }
          stroke={"currentColor"}
        ></path>
      </g>
    </svg>
  );
}

export default LargeHomeHeartIcon;
/* prettier-ignore-end */
