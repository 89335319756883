import React, { PropsWithChildren } from "react";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { registerComponent } from "@plasmicapp/react-web/lib/host";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import FormApiErrorHandler from "./FormApiErrorHandler";
import { DefaultValues } from "react-hook-form/dist/types/form";

// Theoretically we could use refs on the error, but they're not necessarily around.
// See https://github.com/react-hook-form/react-hook-form/issues/612#issuecomment-907783037
const scrollToFirstError = <T extends FieldValues = FieldValues>(errors: FieldErrors<T>) => {
  if (Object.keys(errors).length > 0) {
    const elements = Object.keys(errors)
      .map((name) => document.getElementsByName(name)[0])
      .filter(Boolean);
    elements.sort((a, b) => b.offsetTop - a.offsetTop);
    elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

export interface FormWrapperProps<T extends FieldValues> {
  className?: string;
  defaultValues?: DefaultValues<T>;
  onSubmit: SubmitHandler<T>;
  apiErrors?: Record<string, string>;
  formMethods?: ReturnType<typeof useForm<T>>;
}

function FormWrapper<T extends FieldValues>({
  className,
  children,
  defaultValues,
  apiErrors,
  onSubmit,
  formMethods,
}: PropsWithChildren<FormWrapperProps<T>>) {
  const defaultFormMethods = useForm<T>({ defaultValues });
  formMethods = formMethods || defaultFormMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit, scrollToFirstError)} className={className}>
        <FormApiErrorHandler apiErrors={apiErrors}>{children}</FormApiErrorHandler>
      </form>
    </FormProvider>
  );
}

export default FormWrapper;

export const registerFormWrapperComponent = () =>
  registerComponent(FormWrapper, {
    name: "FormWrapper",
    importPath: "./app/javascript/components/custom/FormWrapper",
    isDefaultExport: true,
    props: {
      children: {
        type: "slot",
        hidePlaceholder: true,
      },
      defaultValues: {
        type: "object",
      },
    },
  });
