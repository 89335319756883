// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRadio2, DefaultRadio2Props } from "./plasmic/remms_4_all/PlasmicRadio2";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface Radio2Props extends DefaultRadio2Props {
  value?: string; // The "active" value assigned by react-hook-form
  onChange?: (value: Radio2Props["value"]) => void;
}

function Radio2_({ value, assignedValue, onChange, ...props }: Radio2Props, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicRadio2
      root={{ ref }}
      {...props}
      isChecked={value === assignedValue}
      onClick={() => onChange?.(assignedValue)}
    />
  );
}

const Radio2 = React.forwardRef(Radio2_);
export default Radio2;
