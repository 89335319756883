// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicPortfolioEntryList,
  DefaultPortfolioEntryListProps,
} from "./plasmic/remms_4_all/PlasmicPortfolioEntryList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ShowLatestPortfolioResponse, PortfolioVersion } from "../api/types";
import PortfolioEntry from "./PortfolioEntry";
import * as p from "@plasmicapp/react-web";
import Button from "./Button";

export interface PortfolioEntryListProps extends DefaultPortfolioEntryListProps {
  portfolioButton: p.Flex<typeof Button>;
  portfolioInfo: ShowLatestPortfolioResponse;
  oldPortfolios: PortfolioVersion[];
  onPortfolioDelete: (portfolio: PortfolioVersion) => void;
}

const setInitialPortfolioList = (portfolioInfo: ShowLatestPortfolioResponse) => {
  if (portfolioInfo?.portfolio_version == null) {
    return [];
  } else {
    return [portfolioInfo.portfolio_version];
  }
};

function PortfolioEntryList_(
  {
    portfolioInfo,
    portfolioButton,
    oldPortfolios,
    oldPortfoliosDisplayed,
    onPortfolioDelete,
    ...props
  }: PortfolioEntryListProps,
  ref: HTMLElementRefOf<"div">,
) {
  const initialPortfolioList = setInitialPortfolioList(portfolioInfo);

  let isEmpty: "step2" | "step1" | undefined = undefined;
  if (portfolioInfo?.esg_questionnaire == null) {
    isEmpty = "step1";
  } else if (portfolioInfo?.portfolio_version == null) {
    isEmpty = "step2";
  }

  return (
    <PlasmicPortfolioEntryList
      root={{ ref }}
      {...props}
      isEmpty={isEmpty}
      showPortfolioIndexButton={isEmpty === undefined}
      initialPortfolioList={initialPortfolioList.map((portfolio) => (
        <PortfolioEntry portfolio={portfolio} key={portfolio.id} onDelete={() => onPortfolioDelete(portfolio)} />
      ))}
      portfolioButton={portfolioButton}
      oldPortfolioList={oldPortfolios.map((portfolio) => (
        <PortfolioEntry portfolio={portfolio} key={portfolio.id} onDelete={() => onPortfolioDelete(portfolio)} />
      ))}
      oldPortfoliosDisplayed={oldPortfoliosDisplayed}
    />
  );
}

const PortfolioEntryList = React.forwardRef(PortfolioEntryList_);
export default PortfolioEntryList;
