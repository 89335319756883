import * as React from "react";
import {
  PlasmicAddressUserSettings,
  DefaultAddressUserSettingsProps,
} from "./plasmic/remms_4_all/PlasmicAddressUserSettings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useTranslation } from "react-i18next";
import { isSuccessful } from "../api/apiEndpoint";
import { toast } from "react-hot-toast";
import { Address, AddressForm, UserUpdateRequest } from "../api/types";
import { useForm } from "react-hook-form";

export interface AddressUserSettingsProps extends DefaultAddressUserSettingsProps, WithAuthenticatedApiServiceProps {
  address?: Address;
  billingAddress?: Address;
}

export interface AddressUpdateForm {
  address_attributes?: AddressForm;
  billing_address_attributes?: AddressForm;
}

const prepareSalutationForForm = (salutation: Address["salutation"]) => {
  return salutation === "male" ? 0 : 1;
};

const prepareAddressForForm = (address: Address | undefined): AddressForm | undefined => {
  if (address === undefined || address === null) {
    return undefined;
  }

  return {
    ...(address || {}),
    salutation: prepareSalutationForForm(address?.salutation ?? "male"),
  };
};

function prepareUpdateRequest(data: AddressUpdateForm, showBillingAddress: boolean): UserUpdateRequest {
  const removeUnwantedAttributes = (address: any) => {
    const { id, created_at, updated_at, ...rest } = address;
    return rest;
  };

  const prepareSalutationForUpdate = (salutation: number) => {
    return Number(salutation) === 0 ? "male" : "female";
  };

  const preparedRequest: UserUpdateRequest = {
    address_attributes: {
      ...removeUnwantedAttributes(data.address_attributes),
      salutation: prepareSalutationForUpdate(data.address_attributes?.salutation || 0),
    },
  };

  if (showBillingAddress) {
    preparedRequest.billing_address_attributes = {
      ...removeUnwantedAttributes(data.billing_address_attributes),
      salutation: prepareSalutationForUpdate(data.billing_address_attributes?.salutation || 0),
    };
  } else {
    preparedRequest.remove_billing_address = true;
  }

  return preparedRequest;
}

function AddressUserSettings_(
  { address, billingAddress, authenticatedApiService, className, ...props }: AddressUserSettingsProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation();
  const [showBillingAddress, setShowBillingAddress] = React.useState(billingAddress !== null);

  const defaultValues: AddressUpdateForm = {
    address_attributes: prepareAddressForForm(address),
    billing_address_attributes: prepareAddressForForm(billingAddress),
  };

  const formMethods = useForm<AddressUpdateForm>({ defaultValues });

  const onSubmit = async (data: AddressUpdateForm) => {
    setIsSubmitting(true);
    const preparedData = prepareUpdateRequest(data, showBillingAddress);
    const response = await authenticatedApiService.currentUser.update(preparedData);

    if (isSuccessful(response)) {
      toast.success(t("toast.address_updated"));
      formMethods.reset();
    } else {
      handleApiError(response);
    }

    setIsSubmitting(false);
  };

  return (
    <FormWrapper defaultValues={defaultValues} className={className} onSubmit={onSubmit} apiErrors={apiErrors}>
      <PlasmicAddressUserSettings
        root={{ ref }}
        {...props}
        addressForm={{
          render: (props, Component) => <Component {...props} />,
        }}
        billingAddressFormCheckbox={{
          onChange: () => setShowBillingAddress(!showBillingAddress),
          value: showBillingAddress,
        }}
        billingAddressForm={{
          render: (props, Component) => showBillingAddress && <Component {...props} />,
        }}
        button={{ isDisabled: isSubmitting, isLoading: isSubmitting }}
      />
    </FormWrapper>
  );
}

const AddressUserSettings = withAuthenticatedApiService(React.forwardRef(AddressUserSettings_));
export default AddressUserSettings;
