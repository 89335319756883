import * as React from "react";
import {
  PlasmicPasswordUserSettings,
  DefaultPasswordUserSettingsProps,
} from "./plasmic/remms_4_all/PlasmicPasswordUserSettings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { PasswordUpdateRequest } from "../api/types";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

type FormValues = PasswordUpdateRequest["registered_user"];

export interface PasswordUserSettingsProps extends DefaultPasswordUserSettingsProps, WithAuthenticatedApiServiceProps {}

function PasswordUserSettings_(
  { authenticatedApiService, className, ...props }: PasswordUserSettingsProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [apiErrors, handleApiErrors] = useApiErrorHandler();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>();

  const onSubmit = async (data: PasswordUpdateRequest["registered_user"]) => {
    setIsSubmitting(true);
    const response = await authenticatedApiService.registrations.update({ registered_user: data });
    setIsSubmitting(false);

    if (isSuccessful(response)) {
      toast.success(t("toast.password_updated"));
      formMethods.reset();
    } else {
      handleApiErrors(response);
    }
  };

  return (
    <FormWrapper<FormValues> className={className} onSubmit={onSubmit} apiErrors={apiErrors} formMethods={formMethods}>
      <PlasmicPasswordUserSettings
        root={{ ref }}
        {...props}
        button={{ isDisabled: isSubmitting, isLoading: isSubmitting }}
      />
    </FormWrapper>
  );
}

const PasswordUserSettings = withAuthenticatedApiService(React.forwardRef(PasswordUserSettings_));
export default PasswordUserSettings;
