import * as React from "react";
import { Plasmic_010Erfassung, Default_010ErfassungProps } from "./plasmic/remms_4_all/Plasmic_010Erfassung";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext, useWatch } from "react-hook-form";
import { DefaultFutureEnergySubCategoryProps } from "./plasmic/remms_4_all/PlasmicFutureEnergySubCategory";
import { Remms4allForm } from "../api/types";

export interface _010ErfassungProps extends Default_010ErfassungProps {
  isLoggedIn: boolean;
  isSubmitting: boolean;
  hasSaveButton: boolean;
  onSaveClick?: (formData: Remms4allForm) => Promise<void>;
}

function convertFutureEnergyMainCategoryToEnergyType(
  futureEnergyMainCategory: undefined | number,
): DefaultFutureEnergySubCategoryProps["energyType"] {
  switch (futureEnergyMainCategory) {
    case 1:
      return "gas";
    case 2:
      return "oel";
    case 3:
      return "fernwaerme";
    case 4:
      return "waermepumpe";
    case 5:
      return "holz";
    case 6:
      return "solarthermie";
    case 7:
      return "elektroheizung";
    case 8:
      return "weitere";
    case 9:
      return "unbekannt";
    case 10:
      return "kein";
    case 11:
      return "stromEigentuemer";
    case 12:
      return "stromMieter";
    case 13:
      return "photovoltaik";
    case undefined:
      return undefined;
    default:
      console.error("Unknown future energy main category: " + futureEnergyMainCategory);
      return undefined;
  }
}

function _010Erfassung_(
  { onSaveClick, hasSaveButton, isLoggedIn, isSubmitting, ...props }: _010ErfassungProps,
  ref: HTMLElementRefOf<"div">,
) {
  const { getValues, setValue } = useFormContext();
  const address = getValues("address");
  const futureEnergyMainCategory = useWatch({ name: "ENERG_SAN_TECH_HAUPTK_DEKL" });
  const [energyType, setEnergyType] = React.useState(
    convertFutureEnergyMainCategoryToEnergyType(futureEnergyMainCategory),
  );

  React.useEffect(() => {
    const newEnergyType = convertFutureEnergyMainCategoryToEnergyType(futureEnergyMainCategory);
    if (newEnergyType !== energyType) {
      setValue("ENERG_SAN_TECH_SUBK_DEKL", null);
      setEnergyType(convertFutureEnergyMainCategoryToEnergyType(futureEnergyMainCategory));
    }
  }, [futureEnergyMainCategory, energyType, setValue]);

  return (
    <Plasmic_010Erfassung
      root={{ ref }}
      processHeader={{ isLoggedIn, address: address }}
      processStickyNav={{
        saveButton: {
          onClick: async () => {
            if (onSaveClick) {
              const values = getValues() as Remms4allForm;
              await onSaveClick(values);
            }
          },
        },
        hasSaveButton,
        isSubmitting,
      }}
      futureEnergySubCategory={{ isDisabled: energyType === undefined, energyType: energyType }}
      {...props}
    />
  );
}

const _010Erfassung = React.forwardRef(_010Erfassung_);
export default _010Erfassung;
