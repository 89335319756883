// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicFormGroup, DefaultFormGroupProps } from "./plasmic/remms_4_all/PlasmicFormGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import WiredFormField from "./custom/WiredFormField";
import TextInput from "./TextInput";
import { RenderControlContext } from "../utils";

const DebugPlaceholder: React.FC = () => <React.Fragment />;
DebugPlaceholder.displayName = "EmptyPlaceholder";

const useWiredFormField = (name: string, component?: React.ReactElement, isRequired = false) => {
  if (!component || name === "") {
    return <DebugPlaceholder />;
  }

  return (
    <WiredFormField className={component?.props?.className} name={name} required={isRequired}>
      {component}
    </WiredFormField>
  );
};

export interface FormGroupProps extends DefaultFormGroupProps {}

function FormGroup_(props: FormGroupProps, ref: HTMLElementRefOf<"div">) {
  const { name, isShownInitially } = props;
  const isRequired = props.isRequired === true || props.isRequired === "isRequired";

  const input = (props.input as React.ReactElement) || React.createElement(TextInput);
  const forwardedProps = {
    ...props,
    input: useWiredFormField(name || "", input, isRequired),
  };

  if (isRequired || isShownInitially) {
    return <PlasmicFormGroup root={{ ref }} {...forwardedProps} />;
  } else {
    return (
      <RenderControlContext.Consumer>
        {({ hidden }) => hidden || <PlasmicFormGroup root={{ ref }} {...forwardedProps} />}
      </RenderControlContext.Consumer>
    );
  }
}

const FormGroup = React.forwardRef(FormGroup_);
export default FormGroup;
