import * as React from "react";
import {
  GUEST_REALTY_ADDRESS_STORAGE_KEY,
  GUEST_REALTY_FORM_DATA_STORAGE_KEY,
  RealtyAddress,
  Remms4allForm,
} from "../api/types";
import { Navigate, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { ApplicationRoutes } from "../ApplicationRoutes";
import NewRealtyForm from "./NewRealtyForm";
import useRealtyFormAttributes from "../useRealtyFormAttributes";
import { useEnsureGuest } from "../useEnsureGuest";
import { useSessionStorage } from "usehooks-ts";

function GuestNewRealtyForm_() {
  const navigate = useNavigate();
  const realtyFormAttributes = useRealtyFormAttributes();
  const setAddress = useSessionStorage<RealtyAddress | null>(GUEST_REALTY_ADDRESS_STORAGE_KEY, null)[1];
  const setFormData = useSessionStorage<Remms4allForm | null>(GUEST_REALTY_FORM_DATA_STORAGE_KEY, null)[1];

  useEnsureGuest();

  const onSubmit = React.useCallback(
    async (formData: Remms4allForm, address: RealtyAddress) => {
      setFormData(formData);
      setAddress(address);
      navigate(ApplicationRoutes.getPath("guestVerification"));
    },
    [navigate, setFormData, setAddress],
  );

  if (isEmpty(realtyFormAttributes.addressString)) {
    return <Navigate to={ApplicationRoutes.getPath("guestRealtyNewAddress")} />;
  }

  return (
    <NewRealtyForm
      hasSaveButton={false}
      onSubmit={onSubmit}
      isSubmitting={false}
      isLoggedIn={false}
      apiErrors={undefined}
      {...realtyFormAttributes}
    />
  );
}

export default GuestNewRealtyForm_;
