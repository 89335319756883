import * as React from "react";
import { PlasmicCheckbox, DefaultCheckboxProps } from "./plasmic/remms_4_all/PlasmicCheckbox";
import { CheckboxRef } from "@plasmicapp/react-web";

export interface CheckboxProps extends Omit<DefaultCheckboxProps, "onChange" | "value"> {
  onChange?: (value: CheckboxProps["value"]) => void;
  value?: boolean;
}

function Checkbox_({ onChange, value, ...props }: CheckboxProps, ref: CheckboxRef) {
  const { plasmicProps } = PlasmicCheckbox.useBehavior<DefaultCheckboxProps>(
    {
      ...props,
      onChange: () => onChange?.(!value),
      isChecked: Boolean(value),
    },
    ref,
  );

  return <PlasmicCheckbox {...plasmicProps} />;
}

const Checkbox = React.forwardRef(Checkbox_);

export default Object.assign(Checkbox, {
  __plumeType: "checkbox",
});
