import * as React from "react";
import {
  PlasmicAccountDeletionUserSettings,
  DefaultAccountDeletionUserSettingsProps,
} from "./plasmic/remms_4_all/PlasmicAccountDeletionUserSettings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useTranslation } from "react-i18next";
import { isSuccessful } from "../api/apiEndpoint";
import { toast } from "react-hot-toast";
import FormWrapper from "./custom/FormWrapper";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useNavigate } from "react-router-dom";
import AuthToken from "../api/AuthToken";

export interface AccountDeletionUserSettingsProps
  extends DefaultAccountDeletionUserSettingsProps,
    WithAuthenticatedApiServiceProps {}

function AccountDeletionUserSettings_(
  { authenticatedApiService, className, ...props }: AccountDeletionUserSettingsProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [apiErrors, handleApiErrors] = useApiErrorHandler();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteAccount = async () => {
    setIsSubmitting(true);

    const response = await authenticatedApiService.currentUser.delete();

    if (isSuccessful(response)) {
      AuthToken.remove();
      navigate(ApplicationRoutes.getPath("login"));
      toast.success(t("toast.account_deleted"));
    } else {
      handleApiErrors(response);
    }

    setIsSubmitting(false);
  };

  const onSubmit = async () => {
    if (window.confirm(t("accountDeletion.deleteConfirm"))) {
      await deleteAccount();
    }
  };

  return (
    <FormWrapper onSubmit={onSubmit} apiErrors={apiErrors} className={className}>
      <PlasmicAccountDeletionUserSettings
        root={{ ref }}
        {...props}
        button={{ isDisabled: isSubmitting, isLoading: isSubmitting }}
      />
    </FormWrapper>
  );
}

const AccountDeletionUserSettings = withAuthenticatedApiService(React.forwardRef(AccountDeletionUserSettings_));
export default AccountDeletionUserSettings;
