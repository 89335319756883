// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicProcessCard, DefaultProcessCardProps } from "./plasmic/remms_4_all/PlasmicProcessCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useEffect } from "react";
import { RenderControlContext } from "../utils";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ProcessCardProps extends Omit<DefaultProcessCardProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultProcessCardProps altogether and have
// total control over the props for your component.
export interface ProcessCardProps extends DefaultProcessCardProps {}

function ProcessCard_(props: ProcessCardProps, ref: HTMLElementRefOf<"div">) {
  const [isClosed, setIsClosed] = React.useState(Boolean(props.isClosed));
  const [optionalFieldsClosed, setOptionalFieldsClosed] = React.useState(true);

  useEffect(() => {
    setIsClosed(Boolean(props.isClosed));
  }, [props.isClosed]);

  return (
    <RenderControlContext.Provider value={{ hidden: optionalFieldsClosed }}>
      <PlasmicProcessCard
        {...props}
        root={{ ref }}
        toggleButton={{ onClick: () => setIsClosed(!isClosed) }}
        toggleOptionalFieldsButton={{
          render: (props, Component) =>
            optionalFieldsClosed && (
              <Component {...props} onClick={() => setOptionalFieldsClosed(!optionalFieldsClosed)} />
            ),
        }}
        isClosed={isClosed}
      />
    </RenderControlContext.Provider>
  );
}

const ProcessCard = React.forwardRef(ProcessCard_);
export default ProcessCard;
