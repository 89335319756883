import * as React from "react";
import { PlasmicDashboardBanner, DefaultDashboardBannerProps } from "./plasmic/remms_4_all/PlasmicDashboardBanner";
import { BannerEntry } from "../api/types";

export interface DashboardBannerProps extends DefaultDashboardBannerProps, BannerEntry {}

function DashboardBanner(props: DashboardBannerProps) {
  const { id, title, text, ...otherProps } = props;

  const getStoredHiddenBanners = () => {
    return JSON.parse(sessionStorage.getItem("hiddenBanners") || "[]");
  };

  const getInitialVisibility = () => {
    return getStoredHiddenBanners().includes(id) || !title;
  };

  const [isHidden, setIsHidden] = React.useState(getInitialVisibility);

  const onDismissClick = () => {
    const hiddenBanners = getStoredHiddenBanners();
    if (!hiddenBanners.includes(id)) {
      hiddenBanners.push(id);
      sessionStorage.setItem("hiddenBanners", JSON.stringify(hiddenBanners));
      setIsHidden(true);
    }
  };

  if (isHidden) {
    return null;
  } else {
    return (
      <PlasmicDashboardBanner
        title={title}
        text={text}
        hasDescription={text !== undefined}
        onDismissClick={onDismissClick}
        {...otherProps}
      />
    );
  }
}

export default DashboardBanner;
