import * as React from "react";
import { PlasmicRadio, DefaultRadioProps } from "./plasmic/remms_4_all/PlasmicRadio";
import { CheckboxRef } from "@plasmicapp/react-web";

interface RadioProps extends DefaultRadioProps {
  // Feel free to add any additional props that this component should receive
}
function Radio_(props: RadioProps, ref: CheckboxRef) {
  const { plasmicProps } = PlasmicRadio.useBehavior<RadioProps>(props, ref);
  return <PlasmicRadio {...plasmicProps} />;
}

const Radio = React.forwardRef(Radio_);

export default Object.assign(Radio, {
  __plumeType: "checkbox",
});
