// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CloudUploadIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CloudUploadIcon(props: CloudUploadIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fill={"currentColor"}
        d={
          "M13 7.1V7c0-2.8-2.2-5-5-5-2.5 0-4.6 1.8-4.9 4.3C1.3 6.9 0 8.5 0 10.5 0 13 2 15 4.5 15H12c2.2 0 4-1.8 4-4 0-1.9-1.3-3.4-3-3.9zM9 10v3H7v-3H4l4-4 4 4H9z"
        }
      ></path>
    </svg>
  );
}

export default CloudUploadIcon;
/* prettier-ignore-end */
