// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Plasmic_002PasswordReset02,
  Default_002PasswordReset02Props,
} from "./plasmic/remms_4_all/Plasmic_002PasswordReset02";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { ResetPasswordForm } from "../api/types";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useTranslation } from "react-i18next";
import FormWrapper from "./custom/FormWrapper";
import { PublicApiService } from "../api/PublicApiService";
import { isSuccessful, isValidationError } from "../api/apiEndpoint";
import { toast } from "react-hot-toast";

export interface _002PasswordReset02Props extends Default_002PasswordReset02Props {}

function _002PasswordReset02_({ className, ...props }: _002PasswordReset02Props, ref: HTMLElementRefOf<"div">) {
  const { token } = useParams();
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!token) return <Navigate to={ApplicationRoutes.getPath("requestPasswordReset")} />;

  const onSubmit = async (data: ResetPasswordForm) => {
    const response = await PublicApiService.password.reset({
      registered_user: {
        ...data,
        reset_password_token: token,
      },
    });

    if (isSuccessful(response)) {
      toast.success(t("toast.password_reset_success"), { duration: 8000 });
      navigate(ApplicationRoutes.getPath("login"));
    } else if (isValidationError(response) && Object.hasOwn(response.validationErrors, "reset_password_token")) {
      toast.error(t("toast.reset_password_token_invalid"), { duration: 8000 });
      navigate(ApplicationRoutes.getPath("requestPasswordReset"));
    } else {
      handleApiError(response);
    }
  };

  return (
    <FormWrapper<ResetPasswordForm> className={className} onSubmit={onSubmit} apiErrors={apiErrors}>
      <Plasmic_002PasswordReset02 root={{ ref }} {...props} />
    </FormWrapper>
  );
}

const _002PasswordReset02 = React.forwardRef(_002PasswordReset02_);
export default _002PasswordReset02;
