import * as React from "react";
import { PlasmicEsgQuestionnaire, DefaultEsgQuestionnaireProps } from "./plasmic/remms_4_all/PlasmicEsgQuestionnaire";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import FormWrapper from "./custom/FormWrapper";
import { ESGQuestionnaireForm } from "../api/types";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useEffect } from "react";
import LoadingScreen from "./custom/LoadingScreen";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash";

export interface EsgQuestionnaireProps extends DefaultEsgQuestionnaireProps, WithAuthenticatedApiServiceProps {}

function EsgQuestionnaire_({ authenticatedApiService, ...props }: EsgQuestionnaireProps, ref: HTMLElementRefOf<"div">) {
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = React.useState<ESGQuestionnaireForm>();
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const formatEsgQuestionnaire = (esgQuestionnaire: ESGQuestionnaireForm) => {
    const coverageAsPercentage = !isNil(esgQuestionnaire.NVB_ABDKG_DEKL)
      ? esgQuestionnaire.NVB_ABDKG_DEKL * 100
      : undefined;

    return {
      ...esgQuestionnaire,
      NVB_ABDKG_DEKL: coverageAsPercentage,
    };
  };

  useEffect(() => {
    setIsLoading(true);
    authenticatedApiService.currentUser.get().then((response) => {
      if (isSuccessful(response)) {
        const esgQuestionnaire = response.data.esg_questionnaire || {};
        if (!esgQuestionnaire?.ESG_QUEST_ORG) {
          esgQuestionnaire.ESG_QUEST_ORG = response.data.address?.organization;
        }
        setDefaultValues(formatEsgQuestionnaire(esgQuestionnaire));
      } else {
        handleApiError(response);
      }
      setIsLoading(false);
    });
  }, [authenticatedApiService, handleApiError]);

  const processFormData = (formData: ESGQuestionnaireForm) => {
    const coverageAsPercentage = formData.NVB_ABDKG_DEKL;
    return {
      ...formData,
      NVB_ABDKG_DEKL: Number(coverageAsPercentage) / 100,
    };
  };

  const onSubmit = async (formData: ESGQuestionnaireForm) => {
    setIsSubmitting(true);

    const response = await authenticatedApiService.currentUser.update({
      esg_questionnaire_attributes: processFormData(formData),
    });

    if (isSuccessful(response)) {
      navigate(ApplicationRoutes.getPath("root"), { replace: true });
    } else {
      handleApiError(response);
    }

    setIsSubmitting(false);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <FormWrapper<ESGQuestionnaireForm>
      className="form-wrapper"
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
    >
      <PlasmicEsgQuestionnaire root={{ ref }} {...props} processEsgStickyNav={{ isSubmitting }} />
    </FormWrapper>
  );
}

const EsgQuestionnaire = withAuthenticatedApiService(React.forwardRef(EsgQuestionnaire_));
export default EsgQuestionnaire;
