import * as React from "react";
import { PlasmicSlider, DefaultSliderProps } from "./plasmic/remms_4_all/PlasmicSlider";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { usePrevious } from "../usePrevious";

export interface SliderProps extends DefaultSliderProps {
  value?: number;
  onChange: (value: number | undefined) => void;
}

function Slider_(props: SliderProps, ref: HTMLElementRefOf<"div">) {
  const { value, onChange, ...rest } = props;
  const preservedValue = usePrevious<number | undefined>(value);

  const handleSliderInputChange = (v: string | undefined) => {
    onChange(v ? parseFloat(v) : undefined);
  };

  const handleEmptySliderInput = () => {
    if (!value) {
      onChange(preservedValue as number);
    }
  };

  return (
    <PlasmicSlider
      root={{ ref }}
      sliderBase={{ value, onChange }}
      sliderInput={{
        value: String(value),
        onChange: (e?: React.ChangeEvent<HTMLInputElement> | string) => {
          typeof e === "string" ? handleSliderInputChange(e) : handleSliderInputChange(e?.target?.value);
        },
        onBlur: handleEmptySliderInput,
      }}
      {...rest}
    />
  );
}

const Slider = React.forwardRef(Slider_);
export default Slider;
