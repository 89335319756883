import * as React from "react";
import {
  PlasmicGeneralUserSettings,
  DefaultGeneralUserSettingsProps,
} from "./plasmic/remms_4_all/PlasmicGeneralUserSettings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { isSuccessful } from "../api/apiEndpoint";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface GeneralUserSettingsProps extends DefaultGeneralUserSettingsProps, WithAuthenticatedApiServiceProps {
  email?: string;
  phoneNumber?: string;
  portfolio: boolean;
}

export interface GeneralUserSettingsForm {
  phone_number: string;
  email: string;
  portfolio: boolean;
}

const prepareData = (data: GeneralUserSettingsForm) => {
  return {
    email: data.email,
    portfolio: data.portfolio,
  };
};

function GeneralUserSettings_(
  { email, phoneNumber, portfolio, authenticatedApiService, className, ...props }: GeneralUserSettingsProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const { t } = useTranslation();

  const defaultValues: GeneralUserSettingsForm = {
    email: email || "",
    phone_number: phoneNumber || "",
    portfolio: portfolio,
  };

  const onSubmit = async (data: GeneralUserSettingsForm) => {
    const preparedData = prepareData(data);
    const response = await authenticatedApiService.currentUser.update(preparedData);
    if (isSuccessful(response)) {
      toast.success(t("toast.user_updated"));
    } else {
      handleApiError(response);
    }
  };

  return (
    <FormWrapper defaultValues={defaultValues} className={className} onSubmit={onSubmit} apiErrors={apiErrors}>
      <PlasmicGeneralUserSettings root={{ ref }} {...props} />
    </FormWrapper>
  );
}

const GeneralUserSettings = withAuthenticatedApiService(React.forwardRef(GeneralUserSettings_));
export default GeneralUserSettings;
