import * as React from "react";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { createSearchParams, useNavigate } from "react-router-dom";
import NewRealtyAddress, { NewRealtyAddressParams } from "./NewRealtyAddress";
import { useEnsureGuest } from "../useEnsureGuest";

export default function GuestNewRealtyAddress_() {
  const navigate = useNavigate();

  useEnsureGuest("authenticatedRealtyNewAddress");

  const onSubmit = React.useCallback(
    async (params: NewRealtyAddressParams) => {
      const queryParams = createSearchParams({
        ...params,
        address: btoa(JSON.stringify(params.address)),
      });

      navigate({
        pathname: ApplicationRoutes.getPath("guestRealtyNewForm"), // TODO: Change to guest form
        search: queryParams.toString(),
      });
    },
    [navigate],
  );

  return <NewRealtyAddress onSubmit={onSubmit} isLoggedIn={false} />;
}
