import * as React from "react";
import {
  PlasmicBenchmarkUserSettings,
  DefaultBenchmarkUserSettingsProps,
} from "./plasmic/remms_4_all/PlasmicBenchmarkUserSettings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { isSuccessful } from "../api/apiEndpoint";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BenchmarkUserSettingsForm } from "../api/types";

export interface BenchmarkUserSettingsProps
  extends DefaultBenchmarkUserSettingsProps,
    WithAuthenticatedApiServiceProps {
  esgBenchmark?: BenchmarkUserSettingsForm;
}

export interface BenchmarkUserSettingsUpdateForm {
  esg_benchmark: BenchmarkUserSettingsForm;
}

function BenchmarkUserSettings_(
  { esgBenchmark, authenticatedApiService, ...props }: BenchmarkUserSettingsProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation();

  const defaultValues = {
    esg_benchmark: esgBenchmark,
  };

  const onSubmit = async (data: BenchmarkUserSettingsUpdateForm) => {
    setIsSubmitting(true);
    const response = await authenticatedApiService.currentUser.update({ esg_benchmark_attributes: data.esg_benchmark });

    if (isSuccessful(response)) {
      toast.success(t("toast.user_updated"));
    } else {
      handleApiError(response);
    }
    setIsSubmitting(false);
  };

  return (
    <FormWrapper defaultValues={defaultValues} className="form-wrapper" onSubmit={onSubmit} apiErrors={apiErrors}>
      <PlasmicBenchmarkUserSettings root={{ ref }} {...props} button={{ isDisabled: isSubmitting }} />
    </FormWrapper>
  );
}

const BenchmarkUserSettings = withAuthenticatedApiService(React.forwardRef(BenchmarkUserSettings_));
export default BenchmarkUserSettings;
