import * as React from "react";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { RealtyAddress, Remms4allForm } from "../api/types";
import { Navigate, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import NewRealtyForm from "./NewRealtyForm";
import useRealtyFormAttributes from "../useRealtyFormAttributes";

function AuthenticatedNewRealtyForm_({ authenticatedApiService }: WithAuthenticatedApiServiceProps) {
  const navigate = useNavigate();
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const realtyFormAttributes = useRealtyFormAttributes();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = React.useCallback(
    async (formData: Remms4allForm, address: RealtyAddress) => {
      setIsSubmitting(true);

      const response = await authenticatedApiService.realties.create_with_report({
        realty: { address },
        realty_snapshot: { remms4all_form: formData },
      });
      setIsSubmitting(false);
      if (isSuccessful(response)) {
        navigate(ApplicationRoutes.getPath("reportCreated", { reportId: response.data.realty_report_id.toString() }), {
          replace: true,
        });
      } else {
        handleApiError(response);
      }
    },
    [navigate, handleApiError, authenticatedApiService],
  );

  const onSave = React.useCallback(
    async (formData: Remms4allForm, address: RealtyAddress) => {
      setIsSubmitting(true);

      const response = await authenticatedApiService.realties.create({
        realty: { address },
        realty_snapshot: { remms4all_form: formData },
      });
      setIsSubmitting(false);
      if (isSuccessful(response)) {
        navigate(ApplicationRoutes.getPath("root"), {
          replace: true,
        });
      } else {
        handleApiError(response);
      }
    },
    [handleApiError, authenticatedApiService, navigate],
  );

  if (isEmpty(realtyFormAttributes.addressString)) {
    return <Navigate to={ApplicationRoutes.getPath("authenticatedRealtyNewAddress")} />;
  }

  return (
    <NewRealtyForm
      hasSaveButton={true}
      onSubmit={onSubmit}
      onSaveClick={onSave}
      isSubmitting={isSubmitting}
      isLoggedIn={true}
      apiErrors={apiErrors}
      {...realtyFormAttributes}
    />
  );
}

const AuthenticatedNewRealtyForm = withAuthenticatedApiService(AuthenticatedNewRealtyForm_);
export default AuthenticatedNewRealtyForm;
