import { ApplicationRoutes } from "./ApplicationRoutes";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PlasmicHost from "./components/PlasmicHost";

export default function App() {
  return (
    <Routes>
      {ApplicationRoutes.map(({ path, component }) => (
        <Route key={path} path={path} element={React.createElement(component)} />
      ))}
      <Route key="plasmic-host" path="plasmic-host" element={<PlasmicHost />} />
      <Route path="*" element={<Navigate to={ApplicationRoutes.getPath("root")} />} />
    </Routes>
  );
}
