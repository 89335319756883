// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AvatarIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AvatarIcon(props: AvatarIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <circle cx={"8"} cy={"4"} r={"4"} fill={"currentColor"}></circle>

        <path
          d={
            "M16 13.2a1.989 1.989 0 00-1.163-1.818A16.654 16.654 0 008 10a16.654 16.654 0 00-6.837 1.382A1.989 1.989 0 000 13.2V16h16z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default AvatarIcon;
/* prettier-ignore-end */
