import * as React from "react";
import FormWrapper from "./custom/FormWrapper";
import WiredFormField from "./custom/WiredFormField";
import TextInput from "./TextInput";
import SingleSelectButtonGroup from "./SingleSelectButtonGroup";
import Button from "./Button";
import MultiSelectButtonGroup from "./MultiSelectButtonGroup";
import Select from "./Select";
import Select__Option from "./Select__Option";
import Slider from "./Slider";
import Switch from "./Switch";
import Checkbox from "./Checkbox";
import Radio2 from "./Radio2";

export const Playground = () => {
  return (
    <div style={{ backgroundColor: "#387055", padding: "1em" }}>
      <h1 style={{ color: "#fff", margin: 0, marginBottom: 10 }}>Playground 🧸</h1>
      <FormWrapper className="form-wrapper" onSubmit={console.log}>
        <WiredFormField className="form-field" name="texty">
          <TextInput {...({} as any)} />
        </WiredFormField>
        <WiredFormField className="other-form-field" name="pushy">
          <SingleSelectButtonGroup>
            <Button text="First Option" shape="rounded" color="grey" buttonType="soft" value="1" />
            <Button text="Second Option" shape="rounded" color="grey" buttonType="soft" value="2" />
          </SingleSelectButtonGroup>
        </WiredFormField>
        <WiredFormField className="other-other-form-field" name="multi-pushy">
          <MultiSelectButtonGroup>
            <Button text="First Option" shape="rounded" color="grey" buttonType="soft" value="1" />
            <Button text="Second Option" shape="rounded" color="grey" buttonType="soft" value="2" />
            <Button text="Third Option" shape="rounded" color="grey" buttonType="soft" value="3" />
          </MultiSelectButtonGroup>
        </WiredFormField>
        <WiredFormField className="dropdown" name="droppy">
          <Select className="droppy">
            <Select__Option value="1">First Option</Select__Option>
            <Select__Option value="2">Second Option</Select__Option>
          </Select>
        </WiredFormField>
        <WiredFormField className="slider" name="slidy">
          <Slider className="slidy" {...({} as any)} />
        </WiredFormField>
        <WiredFormField className="switch" name="switchy">
          <Switch className="switchy" {...({} as any)} />
        </WiredFormField>
        <br />
        <WiredFormField className="checkbox" name="checky">
          <Checkbox className="checky" value={false}>
            My Checkbox 1
          </Checkbox>
        </WiredFormField>
        <WiredFormField className="radio" name="radio">
          <Radio2 text={"test 1"} assignedValue={"test 1"} />
        </WiredFormField>
        <WiredFormField className="radio" name="radio">
          <Radio2 text={"test 2"} assignedValue={"test 2"} />
        </WiredFormField>
        <br />
        <br />
        <input type="submit" />
      </FormWrapper>
    </div>
  );
};
