import * as React from "react";
import { isNil } from "lodash";
import { PlasmicPortfolioEntry, DefaultPortfolioEntryProps } from "./plasmic/remms_4_all/PlasmicPortfolioEntry";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { PortfolioVersion, PortfolioSnapshotListEntry, RealtyAddress, SnapshotReport } from "../api/types";
import PortfolioDetailRow from "./PortfolioDetailRow";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useCallback } from "react";
import {
  formatDate,
  formatAddress,
  formatNumberWithApostrophes,
  formatDecimalToPercent,
  formatRatingNumber,
} from "../utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

export interface PortfolioEntryProps extends DefaultPortfolioEntryProps, WithAuthenticatedApiServiceProps {
  portfolio: PortfolioVersion;
  onDelete?: () => void;
}

const setPropsForPortfolioEntry = (portfolio: PortfolioVersion) => {
  return {
    portfolioName: portfolio.name,
    esgDate: formatDate(portfolio.energy_data_referral_date),
    creationDate: formatDate(portfolio.realty_data_referral_date),
    coverage: formatDecimalToPercent(portfolio.portfolio_report?.api_response?.PF_ABDECKUNGSGRAD_OUTPUT),
    oekologieRating: formatRatingNumber(portfolio.portfolio_report?.api_response?.PF_GESAMTRAT_OEKOLOGIE_EBF_MW),
    oekonomieRating: formatRatingNumber(portfolio.portfolio_report?.api_response?.PF_GESAMTRAT_OEKONOMIE_EBF_MW),
    sozialRating: formatRatingNumber(portfolio.portfolio_report?.api_response?.PF_GESAMTRAT_SOZIALES_EBF_MW),
    pfSc12VerbTot: formatNumberWithApostrophes(portfolio.portfolio_report?.api_response?.PF_SC12_VERB_TOT, 0),
    pfSc12VerbM2EbfLabel: portfolio.portfolio_report?.api_response?.PF_SC12_VERB_M2EBF_LABEL ?? "--",
    pfSc12VerbM2VmfLabel: portfolio.portfolio_report?.api_response?.PF_SC12_VERB_M2VMF_LABEL ?? "--",
    pfSc12AnteilFossilEnerg: formatDecimalToPercent(
      portfolio.portfolio_report?.api_response?.PF_SC12_ANTEIL_FOSSIL_ENERG ?? undefined,
    ),
    pfSc12AnteilErneuEnerg: formatDecimalToPercent(
      portfolio.portfolio_report?.api_response?.PF_SC12_ANTEIL_ERNEU_ENERG ?? undefined,
    ),
    pfSc12AnteilAbwaermEnerg: formatDecimalToPercent(
      portfolio.portfolio_report?.api_response?.PF_SC12_ANTEIL_ABWAERM_ENERG ?? undefined,
    ),
    pfSc12EmissTot: formatNumberWithApostrophes(portfolio.portfolio_report?.api_response?.PF_SC12_EMISS_TOT, 0),
    pfSc12EmissM2EbfLabel: portfolio.portfolio_report?.api_response?.PF_SC12_EMISS_M2EBF_LABEL,
    pfSc12EmissM2VmfLabel: portfolio.portfolio_report?.api_response?.PF_SC12_EMISS_M2VMF_LABEL,
    pfSc12Gruenercapex30: formatNumberWithApostrophes(
      portfolio.portfolio_report?.api_response?.PF_SC12_GRUENERCAPEX_30,
      1,
    ),
    pfSc12Gruenercapex40: formatNumberWithApostrophes(
      portfolio.portfolio_report?.api_response?.PF_SC12_GRUENERCAPEX_40,
      1,
    ),
    pfSc12Gruenercapex50: formatNumberWithApostrophes(
      portfolio.portfolio_report?.api_response?.PF_SC12_GRUENERCAPEX_50,
      1,
    ),
  };
};

function formatDataDate(snapshotReport: SnapshotReport) {
  return `${snapshotReport.DATENSTAND_IMMOWERT_TAG}.${snapshotReport.DATENSTAND_IMMOWERT_MONAT}.${snapshotReport.DATENSTAND_IMMOWERT_JAHR}`;
}

function formatRealtyAddress(realtyAddress: RealtyAddress) {
  return formatAddress(realtyAddress, true);
}

function formatRating(rating: number | string) {
  if (typeof rating === "string") {
    return rating;
  } else {
    return rating.toFixed(1).toString();
  }
}

const buildSnapshotList = (snapshots: any) => {
  return snapshots.map((snapshot: any) => ({
    id: snapshot.id.toString(),
    snapshotReportLink: snapshot.pdf_report_url,
    verbrauch: snapshot.snapshot_report.VERBRAUCH_GESAMTENERG.toString(),
    emission: snapshot.snapshot_report.EMISSIONEN_GESAMTENERG,
    dataDate: formatDataDate(snapshot.snapshot_report),
    modelDate: snapshot.snapshot_report.MODVERSOUT,
    refNr: snapshot.remms4all_form.REF_NR,
    version: snapshot.remms4all_form.VERSION,
    address: formatRealtyAddress(snapshot.realty_address),
    oekonomieRating: formatRating(snapshot.snapshot_report.GESAMTRAT_OEKONOMIE),
    oekologieRating: formatRating(snapshot.snapshot_report.GESAMTRAT_OEKOLOGIE),
    sozialRating: formatRating(snapshot.snapshot_report.GESAMTRAT_SOZIALES),
  }));
};

function PortfolioEntry_(
  { authenticatedApiService, portfolio, onDelete, ...props }: PortfolioEntryProps,
  ref: HTMLElementRefOf<"div">,
) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [snapshotList, setSnapshotList] = React.useState([]);
  const [allRealtiesFetched, setAllRealtiesFetched] = React.useState(false);

  const propsForPortfolioEntry = setPropsForPortfolioEntry(portfolio);
  const portfolioReportUrl = portfolio.portfolio_report?.pdf_report_url || undefined;
  const { t } = useTranslation();
  const objectCount = t("portfolioEntry.objectCount", { count: portfolio.realty_snapshots_count });

  const handleApiError = useApiErrorHandler()[1];

  const fetchRealtySnapshots = useCallback(() => {
    authenticatedApiService.portfolioVersions
      .portfolioVersion(portfolio.id.toString())
      .listSnapshots()
      .then((response) => {
        if (isSuccessful(response)) {
          setSnapshotList(buildSnapshotList(response.data));
          setAllRealtiesFetched(true);
        } else {
          handleApiError(response);
        }
      });
  }, [authenticatedApiService, handleApiError, portfolio.id]);

  const handleCollapseButtonClick = () => {
    if (!isExpanded && !allRealtiesFetched) {
      fetchRealtySnapshots();
    }
    setIsExpanded(!isExpanded);
  };

  const handleDeleteButtonClick = () => {
    if (window.confirm(t("portfolioEntry.softDeleteConfirm"))) {
      authenticatedApiService.portfolioVersions
        .portfolioVersion(portfolio.id.toString())
        .delete()
        .then((response) => {
          if (isSuccessful(response)) {
            toast.success(t("portfolioEntry.portfolioDeleted"));
            onDelete?.();
          } else {
            handleApiError(response);
          }
        });
    }
  };

  return (
    <PlasmicPortfolioEntry
      root={{ ref }}
      {...props}
      {...propsForPortfolioEntry}
      isExpanded={isExpanded}
      hasEsgDate={!!portfolio.energy_data_referral_date}
      hasRealtyDate={!!portfolio.realty_data_referral_date}
      portfolioCollapseButton={{ onClick: () => handleCollapseButtonClick() }}
      reportAvailable={!isNil(portfolioReportUrl)}
      downloadButton={{
        onClick: () => {
          window.open(portfolioReportUrl, "_blank");
        },
      }}
      deleteButton={{ onClick: handleDeleteButtonClick }}
      snapshotList={{
        children: snapshotList.map((snapshot: PortfolioSnapshotListEntry) => (
          <PortfolioDetailRow snapshot={snapshot} key={snapshot.id} />
        )),
      }}
      objectCount={objectCount}
    />
  );
}

const PortfolioEntry = withAuthenticatedApiService(React.forwardRef(PortfolioEntry_));
export default PortfolioEntry;
