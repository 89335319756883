// Entry point for the build script in your package.json

import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./sentry";
import { PlasmicRootProvider } from "@plasmicapp/react-web";
import { BrowserRouter } from "react-router-dom";
import usePlasmicTranslator from "./usePlasmicTranslator";
import "i18n/i18n";
import { useTranslation } from "react-i18next";
import App from "./App";
import PlasmicGlobalContextsProvider from "./components/plasmic/remms_4_all/PlasmicGlobalContextsProvider";
import { Toaster } from "react-hot-toast";

const root = document.getElementById("root")!;
const locale = root.getAttribute("data-locale")!;

const Root: React.FC = () => {
  const translator = usePlasmicTranslator();
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n]);

  return (
    <>
      <PlasmicRootProvider i18n={{ translator: translator }}>
        <PlasmicGlobalContextsProvider>
          <BrowserRouter basename={`/${locale}`}>
            <App />
          </BrowserRouter>
        </PlasmicGlobalContextsProvider>
      </PlasmicRootProvider>
      <Toaster />
    </>
  );
};

createRoot(root).render(<Root />);

// Fix scrolling to interfer with number fields on chrome based browsers.
// See https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number/38589039#38589039
document.addEventListener("wheel", function () {
  if (document.activeElement instanceof HTMLInputElement) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  }
});
