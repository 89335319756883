// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Default_013Registrierung03Props,
  Plasmic_013Registrierung03,
} from "./plasmic/remms_4_all/Plasmic_013Registrierung03";

import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { withAuthenticatedApiService, WithAuthenticatedApiServiceProps } from "../api/AuthenticatedApiService";
import { DefaultTermsOfUseForm, RegistrationAddressForm, TermsOfUseForm, UserUpdateRequest } from "../api/types";
import { Navigate, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isSuccessful } from "../api/apiEndpoint";
import AuthToken from "../api/AuthToken";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { FormProvider, useForm } from "react-hook-form";
import FormApiErrorHandler from "./custom/FormApiErrorHandler";
import { patchTermsOufUseFormData } from "../utils";

export interface _013Registrierung03Props extends Default_013Registrierung03Props, WithAuthenticatedApiServiceProps {}

const prepareSalutation = (salutation: any) => {
  return (salutation as number) === 0 ? "male" : "female";
};

function prepareUpdateRequest(data: RegistrationAddressForm): UserUpdateRequest {
  const terms_of_use = patchTermsOufUseFormData(data.terms_of_use);

  return {
    address_attributes: {
      ...data.address_attributes,
      salutation: prepareSalutation(data.address_attributes.salutation),
    },
    billing_address_attributes: data.billing_address_attributes && {
      ...data.billing_address_attributes,
      salutation: prepareSalutation(data.billing_address_attributes.salutation),
    },
    terms_of_use: terms_of_use,
  };
}

function _013Registrierung03_(
  { authenticatedApiService, ...props }: _013Registrierung03Props,
  ref: HTMLElementRefOf<"div">,
) {
  const defaultValues = {
    terms_of_use: DefaultTermsOfUseForm,
  };
  const formMethods = useForm<RegistrationAddressForm>({ defaultValues });

  const [showBillingAddress, setShowBillingAddress] = React.useState(false);
  const navigate = useNavigate();
  const [apiErrors, handleApiError] = useApiErrorHandler();

  const [editTermsOfUse, setEditTermsOfUse] = React.useState(false);
  const [termsOfUseAccepted, setTermsOfUseAccepted] = React.useState<boolean>(false);
  const [termsOfUseIndeterminate, setTermsOfUseIndeterminate] = React.useState<boolean>(false);
  const watchTermsOfUse = formMethods.watch("terms_of_use");
  const [submitInProgress, setSubmitInProgress] = React.useState(false);

  const onSubmit = async (data: RegistrationAddressForm) => {
    setSubmitInProgress(true);
    const preparedData = prepareUpdateRequest(data);

    if (!showBillingAddress) {
      delete preparedData.billing_address_attributes;
    }

    const response = await authenticatedApiService.currentUser.update(preparedData);
    setSubmitInProgress(false);

    if (isSuccessful(response)) {
      const updatedToken = response.headers.get("Authorization");
      if (!updatedToken) {
        throw new Error("No token in response");
      }
      AuthToken.set(updatedToken);
      navigate(ApplicationRoutes.getPath("root"));
    } else {
      handleApiError(response);
    }
  };

  const onCloseTermsOfUse = () => {
    setEditTermsOfUse(false);
    if (watchTermsOfUse) {
      setTermsOfUseAccepted(true);
      // Check if anything not in EXCLUSION isn't set
      const EXCLUSION = ["data_usage"];
      const countFalse = (Object.keys(watchTermsOfUse) as Array<keyof TermsOfUseForm>).filter(
        (k, _i) => !EXCLUSION.includes(k) && !watchTermsOfUse[k],
      ).length;
      setTermsOfUseIndeterminate(countFalse > 0);
    }
  };

  if (authenticatedApiService.authToken.userCompletedSignup()) {
    return <Navigate to={ApplicationRoutes.getPath("root")} replace={true} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="registration-address">
        <FormApiErrorHandler apiErrors={apiErrors}>
          <Plasmic_013Registrierung03
            showTermsOfUse={editTermsOfUse}
            termsOfUseModal={{
              onClose: () => onCloseTermsOfUse(),
            }}
            root={{ ref }}
            {...props}
            billingAddressFormCheckbox={{
              value: showBillingAddress,
              onChange: () => setShowBillingAddress(!showBillingAddress),
            }}
            billingAddressForm={{
              render: (props, Component) => showBillingAddress && <Component {...props} />,
            }}
            checkboxTermsOfUse={{
              onChange: () => setEditTermsOfUse(true),
              value: termsOfUseAccepted,
              isChecked: termsOfUseAccepted,
              isIndeterminate: termsOfUseIndeterminate,
            }}
            submitButton={{
              isDisabled: !termsOfUseAccepted || submitInProgress,
              isLoading: submitInProgress,
            }}
          />
        </FormApiErrorHandler>
      </form>
    </FormProvider>
  );
}

const _013Registrierung03 = React.forwardRef(_013Registrierung03_);
export default withAuthenticatedApiService(_013Registrierung03, {
  redirectOnIncompleteSignup: false,
});
