import { ary, identity, isObjectLike, mapValues, pickBy } from "lodash";
import { ensureValueOrNull } from "../utils";

export type PrimitiveTypes = string | number | boolean | null | undefined;
export type JSONSerializable = {
  [key: string]: PrimitiveTypes | JSONSerializable | Array<JSONSerializable>;
};
type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

// TODO: Get from ENV
export interface EndpointConfig {
  path: string;
  method: HTTPMethod;
  headers?: Record<string, string>;
}

interface HTTPResponse {
  status: number;
  statusText: string;
  headers: Headers;
}

interface ValidationErrorResponse extends HTTPResponse {
  validationErrors: { [x: string]: string };
}

export interface ApplicationErrorResponse extends HTTPResponse {
  applicationErrors: string[];
}

type AuthenticationErrorResponse = HTTPResponse;

export interface ExceptionResponse {
  exception: Error;
  description?: string;
}

export interface SuccessfulResponse<T> extends HTTPResponse {
  data: T;
}

export interface PaymentRequiredErrorResponse<T> extends HTTPResponse {
  data?: T;
}

export type ApiResponse<Res> =
  | SuccessfulResponse<Res>
  | ValidationErrorResponse
  | ApplicationErrorResponse
  | AuthenticationErrorResponse
  | PaymentRequiredErrorResponse<Res>
  | ExceptionResponse;

function responseFromException(exception: Error, description?: string): ExceptionResponse {
  return { exception, description };
}

function formattedResponseBody(data: any) {
  if (data == null || (!("validation_errors" in data) && !("application_errors" in data) && !("error" in data))) {
    return { data };
  }

  return pickBy(
    {
      validationErrors: data.validation_errors,
      applicationErrors: data.application_errors ?? [data.error],
    },
    ary(identity, 1),
  ) as Omit<ValidationErrorResponse, keyof HTTPResponse>;
}

async function apiResponseFromHTTPResponse<Res>(httpResponse: Response): Promise<ApiResponse<Res>> {
  try {
    const data = httpResponse.status === 204 || httpResponse.status === 429 ? {} : await httpResponse.json();
    return {
      status: httpResponse.status,
      statusText: httpResponse.statusText,
      headers: httpResponse.headers,
      ...formattedResponseBody(data),
    };
  } catch (e) {
    return responseFromException(e as Error, "Serverantwort konnte nicht interpretiert werden");
  }
}

function buildApiRequest<Req extends JSONSerializable | undefined>(
  { headers = {}, ...endpoint }: EndpointConfig,
  requestBody?: Req,
) {
  const apiUrl = window.REMMS4ALL.apiUrl.replace(/\/$/, "");
  let requestUrl = `${apiUrl}/${endpoint.path.replace(/^\//, "")}`;
  const requestOptions: RequestInit = { method: endpoint.method };

  const urlParams = new URLSearchParams();
  const root = document.getElementById("root")!;
  if (root?.getAttribute("data-locale")) {
    urlParams.append("locale", root.getAttribute("data-locale") ?? "de"); // TODO: get current locale from i18n
  }

  if (isObjectLike(requestBody)) {
    if (endpoint.method === "GET" && requestBody) {
      Object.entries(requestBody).forEach(([key, value]) => urlParams.append(key, String(value)));
    } else {
      headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(mapValues(requestBody, ensureValueOrNull));
    }
  }

  if (urlParams.toString()) {
    requestUrl += `?${urlParams.toString()}`;
  }

  requestOptions.headers = new Headers(headers);
  return { requestUrl, requestOptions };
}

async function apiRequest<Req extends JSONSerializable | undefined, Res>(
  endpoint: EndpointConfig,
  requestBody?: Req,
): Promise<ApiResponse<Res>> {
  const { requestUrl, requestOptions } = buildApiRequest(endpoint, requestBody);

  try {
    const response = await fetch(requestUrl, requestOptions);
    return await apiResponseFromHTTPResponse<Res>(response);
  } catch (e) {
    return responseFromException(e as Error, "API Server ist nicht verfügbar");
  }
}

export type Request = { [x: string]: any } | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiEndpoint<Req extends Request, Res>(options: EndpointConfig) {
  return async (request: Req extends undefined ? void : Req) => {
    return await apiRequest<Req, Res>(options, request as Req);
  };
}

export function isSuccessful<T>(response: ApiResponse<T>): response is SuccessfulResponse<T> {
  if ("status" in response && (response.status < 200 || response.status >= 300)) {
    return false;
  }

  return !("errors" in response) && !("exception" in response);
}

export function isValidationError<T>(response: ApiResponse<T>): response is ValidationErrorResponse {
  return typeof (response as ValidationErrorResponse).validationErrors === "object";
}

export function isApplicationError<T>(response: ApiResponse<T>): response is ApplicationErrorResponse {
  return "applicationErrors" in response && Array.isArray((response as ApplicationErrorResponse).applicationErrors);
}

export function isRequestLimitError<T>(response: ApiResponse<T>): response is HTTPResponse {
  return "status" in response && response.status === 429;
}

export function isAuthenticationError<T>(response: ApiResponse<T>): response is AuthenticationErrorResponse {
  return "status" in response && response.status === 401;
}

export function isPaymentRequiredErrorResponse<T>(
  response: ApiResponse<T>,
): response is PaymentRequiredErrorResponse<T> {
  return "status" in response && response.status === 402;
}
