// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { ChangeEvent, MouseEvent, useRef, useState } from "react";
import { PlasmicFileUploadField, DefaultFileUploadFieldProps } from "./plasmic/remms_4_all/PlasmicFileUploadField";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { DirectUpload, Blob } from "@rails/activestorage";
import AuthToken from "../api/AuthToken";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface FileUploadFieldProps extends DefaultFileUploadFieldProps {
  directUploadUrl: string;
  authToken: AuthToken;
  onUploadDone: (blob: Blob) => void;
}

class Uploader {
  directUpload: DirectUpload;
  setUploadProgress: (progress: number) => void;

  constructor(
    authToken: AuthToken,
    directUploadUrl: string,
    file: File,
    setUploadProgress: (progress: number) => void,
  ) {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    this.directUpload = new DirectUpload(file, directUploadUrl, this, headers);
    this.setUploadProgress = setUploadProgress;
  }

  create(callback: (error: Error, blob: Blob) => void): void {
    this.directUpload.create(callback);
  }

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event: ProgressEvent) {
    const progress = Math.round((event.loaded / event.total) * 100);
    this.setUploadProgress(progress);
  }
}

function FileUploadField_(
  { onUploadDone, directUploadUrl, authToken, ...props }: FileUploadFieldProps,
  ref: HTMLElementRefOf<"div">,
) {
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState<number | null>(null); // null = not uploading, 0-100 = uploading

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  const uploadFile = async (file: File) => {
    setUploadProgress(0);
    const uploader = new Uploader(authToken, directUploadUrl, file, setUploadProgress);

    uploader.create((error, blob) => {
      if (error) {
        console.error(error);
        toast.error(t("toast.upload_failed"));
      } else {
        setUploadProgress(100);
        onUploadDone(blob);
      }
      setUploadProgress(null);
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadFile(file);
    }
  };

  return (
    <>
      <PlasmicFileUploadField
        root={{ ref }}
        {...props}
        onClick={handleClick}
        isLoading={uploadProgress !== null}
        progressBar={{ style: { width: `${uploadProgress}%` }, "aria-valuenow": uploadProgress ?? 0 }}
      />
      <input
        ref={fileInputRef}
        type="file"
        hidden
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        data-direct-upload-url={directUploadUrl}
        onChange={handleChange}
      />
    </>
  );
}

const FileUploadField = React.forwardRef(FileUploadField_);
export default FileUploadField;
