import * as React from "react";
import {
  PlasmicDynamicEnergyListItem,
  DefaultDynamicEnergyListItemProps,
} from "./plasmic/remms_4_all/PlasmicDynamicEnergyListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DynamicEnergyListItemProps extends DefaultDynamicEnergyListItemProps {}

function DynamicEnergyListItem_({ onDelete, ...props }: DynamicEnergyListItemProps, ref: HTMLElementRefOf<"div">) {
  return <PlasmicDynamicEnergyListItem root={{ ref }} {...props} itemDeleteButton={{ onClick: onDelete }} />;
}

const DynamicEnergyListItem = React.forwardRef(DynamicEnergyListItem_);
export default DynamicEnergyListItem;
