// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicLangSelectItem, DefaultLangSelectItemProps } from "./plasmic/remms_4_all/PlasmicLangSelectItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { switchLanguage } from "../utils";
import { useTranslation } from "react-i18next";
import { Locale } from "../api/types";

export interface LangSelectItemProps extends DefaultLangSelectItemProps {}

function LangSelectItem_(props: LangSelectItemProps, ref: HTMLElementRefOf<"a">) {
  const { i18n } = useTranslation();

  const text = (props.text as React.ReactElement).props.children;
  const currentLocale = i18n.language;
  let isActive = props.isActive;
  let targetLocale: Locale = "de";

  if (text) {
    const locale = text.toLowerCase();
    isActive = locale === currentLocale;
    targetLocale = locale;
  } else {
    console.error("LangSelectItem doesn't have text property or it isn't a string");
  }

  return (
    <PlasmicLangSelectItem
      onClick={() => switchLanguage(targetLocale)}
      navLink={{ ref }}
      {...props}
      isActive={isActive}
    />
  );
}

const LangSelectItem = React.forwardRef(LangSelectItem_);
export default LangSelectItem;
