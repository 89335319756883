// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicDynamicEnergyList,
  DefaultDynamicEnergyListProps,
} from "./plasmic/remms_4_all/PlasmicDynamicEnergyList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext, useWatch } from "react-hook-form";
import flattenChildren from "react-keyed-flatten-children";
import { isValidElement, ReactElement } from "react";
import DynamicEnergyListItem from "./DynamicEnergyListItem";

export interface DynamicEnergyListProps extends DefaultDynamicEnergyListProps {}

function DynamicEnergyList_(props: DynamicEnergyListProps, ref: HTMLElementRefOf<"div">) {
  const selectableEnergies = [
    "ENR_ENTR_GAS_ANT",
    "ENR_ENTR_OEL_ANT",
    "ENR_ENTR_FWAE_ANT",
    "ENR_ENTR_WP_ANT",
    "ENR_ENTR_HLZ_ANT",
    "ENR_ENTR_SOLT_ANT",
    "ENR_ENTR_ELEKHZ_ANT",
    "ENR_ENTR_WEITR_ANT",
    "ENR_ENTR_UNBEKN_ANT",
  ];
  const selectableEnergiesSuffix = ["ANT", "EINH", "SUBK", "VERB"];
  const energyDummyField = useWatch({ name: "energyDummyField" });
  const selectedMainEnergy = useWatch({ name: "HeizHauptEnerTrKat" });
  const selectedEnergies = useWatch({ name: selectableEnergies });
  const { setValue, unregister } = useFormContext();

  // Migrate from a single HeizHauptEnerTrKat field to multiple
  if (props.limit !== 1 && selectedMainEnergy) {
    setValue(selectableEnergies[selectedMainEnergy - 1], 1);
    unregister("HeizHauptEnerTrKat");
  }

  React.useEffect(() => {
    if (!energyDummyField) {
      return;
    }

    if (props.limit === 1) {
      setValue("HeizHauptEnerTrKat", energyDummyField);
    } else {
      setValue(selectableEnergies[energyDummyField - 1], 1);
    }

    setValue("energyDummyField", null); // set to null, to allow to select the same field again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyDummyField]);

  const onRemove = (index: number) => {
    if (props.limit === 1) {
      setValue("HeizHauptEnerTrKat", null);
    }
    selectableEnergiesSuffix.forEach((suffix) => {
      const fieldName = selectableEnergies[index].replace("ANT", suffix);
      setValue(fieldName, null);
    });
  };

  const items = flattenChildren(props.items || [])
    .map((item, index) => {
      if (isValidElement(item) && item.type === DynamicEnergyListItem) {
        return React.cloneElement(item as ReactElement, {
          onDelete: () => {
            onRemove(index);
          },
        });
      }
      return item;
    })
    .filter((_item, index) => {
      return selectedEnergies[index] || selectedMainEnergy === index + 1;
    });

  const hiddenAddButton = props.limit ? items.length >= props.limit : false;

  return <PlasmicDynamicEnergyList root={{ ref }} {...props} items={items} hiddenAddButton={hiddenAddButton} />;
}

const DynamicEnergyList = React.forwardRef(DynamicEnergyList_);
export default DynamicEnergyList;
