import * as React from "react";
import {
  PlasmicSingleSelectButtonGroup,
  DefaultSingleSelectButtonGroupProps,
} from "./plasmic/remms_4_all/PlasmicSingleSelectButtonGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import PlasmicButton, { DefaultButtonProps } from "./plasmic/remms_4_all/PlasmicButton";
import { getValOfPlasmicComponent } from "../utils";

export interface SingleSelectButtonGroupProps extends DefaultSingleSelectButtonGroupProps {
  children?: Iterable<
    React.ReactElement<DefaultButtonProps & { "data-test-active": string; "data-value": number }, typeof PlasmicButton>
  >;
  // value can be unknown if the form is empty, a number when something is selected or null if the user deselects.
  value?: number | null;
  onChange?: (value?: number | null) => void;
}

function SingleSelectButtonGroup_(
  { children, value, onChange, ...rest }: SingleSelectButtonGroupProps,
  ref: HTMLElementRefOf<"div">,
) {
  if (!children) return <PlasmicSingleSelectButtonGroup root={{ ref }} {...rest} />;

  const modifiedChildren = Array.from(children).map((child, index) => {
    const buttonValue = Number(getValOfPlasmicComponent(child));
    const buttonActive = value === buttonValue;

    return React.cloneElement(child, {
      onClick: () => {
        onChange && onChange(buttonActive ? null : buttonValue);
      },
      buttonType: buttonActive ? "solid" : "soft",
      submitsForm: false,
      type: "button", // prevent form submission, always
      "data-test-active": buttonActive ? "true" : "false",
      key: index,
      "data-value": buttonValue,
    });
  });

  return (
    <PlasmicSingleSelectButtonGroup root={{ ref }} {...rest}>
      {modifiedChildren}
    </PlasmicSingleSelectButtonGroup>
  );
}

const SingleSelectButtonGroup = React.forwardRef(SingleSelectButtonGroup_);
export default SingleSelectButtonGroup;
