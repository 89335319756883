// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AlgoIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AlgoIcon(props: AlgoIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6 12a1 1 0 01-1-1V1a1 1 0 012 0v10a1 1 0 01-1 1zm-4-2a1 1 0 01-1-1V3a1 1 0 012 0v6a1 1 0 01-1 1zm8-2a1 1 0 01-1-1V5a1 1 0 012 0v2a1 1 0 01-1 1z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default AlgoIcon;
/* prettier-ignore-end */
