// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Plasmic_013Registrierung02,
  Default_013Registrierung02Props,
} from "./plasmic/remms_4_all/Plasmic_013Registrierung02";
import FormApiErrorHandler from "./custom/FormApiErrorHandler";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { SignUpRequest } from "../api/types";
import { FormProvider, useForm } from "react-hook-form";
import { SuccessfulResponse, isSuccessful, isValidationError } from "../api/apiEndpoint";
import { PublicApiService } from "../api/PublicApiService";
import AuthToken from "../api/AuthToken";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import usePhoneVerification from "../usePhoneVerification";

export interface _013Registrierung02Props extends Default_013Registrierung02Props {}

type FormInputs = SignUpRequest["registered_user"];

function _013Registrierung02_(props: _013Registrierung02Props, ref: HTMLElementRefOf<"div">) {
  const [apiErrors, setApiErrors] = React.useState<Record<string, string> | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formMethods = useForm<FormInputs>();
  const watchPhoneNumber = formMethods.watch("phone_number");
  const phoneVerification = usePhoneVerification(watchPhoneNumber);
  const [submitInProgress, setSubmitInProgress] = React.useState(false);

  if (AuthToken.get()?.isExpired() === false) {
    return <Navigate to={ApplicationRoutes.getPath("root")} replace={true} />;
  }

  const processSignupResponse = (response: SuccessfulResponse<object>) => {
    const token = response.headers.get("Authorization");
    if (!token) {
      toast(t("toast.unexpected_error_try_again"));
      console.error("Couldn't find Authorization header in response: ", response);
      return;
    }

    AuthToken.set(token);
    navigate(ApplicationRoutes.getPath("signupAddress"));
  };

  const onSubmit = async (formData: FormInputs) => {
    setSubmitInProgress(true);
    const response = await PublicApiService.session.signUp({
      registered_user: formData,
    });
    setSubmitInProgress(false);
    if (isSuccessful(response)) {
      processSignupResponse(response);
    } else if (isValidationError(response)) {
      setApiErrors(response.validationErrors);
    } else {
      toast(t("toast.unexpected_error_try_again"));
      console.error(response);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="signup-form">
        <FormApiErrorHandler apiErrors={{ ...apiErrors, ...phoneVerification.apiErrors }}>
          <Plasmic_013Registrierung02
            phoneVerificationButton={{
              onClick: phoneVerification.onPhoneVerifyClick,
              isDisabled: phoneVerification.isPhoneVerificationButtonDisabled,
            }}
            button={{
              isDisabled: !phoneVerification.hasStartedVerification || submitInProgress,
              isLoading: submitInProgress,
            }}
            root={{ ref }}
            {...props}
          />
        </FormApiErrorHandler>
      </form>
    </FormProvider>
  );
}

const _013Registrierung02 = React.forwardRef(_013Registrierung02_);
export default _013Registrierung02;
