import * as React from "react";
import { PublicApiService } from "./api/PublicApiService";
import { isSuccessful } from "./api/apiEndpoint";
import { useApiErrorHandler } from "./useApiErrorHandler";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const MINIMUM_PHONE_NUMBER_LENGTH = 10;
const PHONE_VERIFY_TIMEOUT_MS = 60000;

const usePhoneVerification = (phoneNumber?: string) => {
  const [phoneVerifyButtonDisabled, setPhoneVerifyButtonDisabled] = React.useState<boolean>(false);
  const [hasStartedVerification, setHasStartedVerification] = React.useState<boolean>(false);
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const { t } = useTranslation();

  const isPhoneVerificationButtonDisabled = () => {
    if (phoneNumber === undefined) {
      return true;
    }

    return phoneNumber.length < MINIMUM_PHONE_NUMBER_LENGTH || phoneVerifyButtonDisabled;
  };

  const onPhoneVerifyClick = async () => {
    if (phoneNumber === undefined) return;

    const response = await PublicApiService.verify_phone_number.start({
      phone_number: phoneNumber,
    });

    if (isSuccessful(response)) {
      setHasStartedVerification(true);
      setPhoneVerifyButtonDisabled(true);
      setTimeout(() => {
        setPhoneVerifyButtonDisabled(false);
      }, PHONE_VERIFY_TIMEOUT_MS);
      toast.success(t("toast.verification_code_sent"));
    } else {
      handleApiError(response);
    }
  };

  return {
    isPhoneVerificationButtonDisabled: isPhoneVerificationButtonDisabled(),
    onPhoneVerifyClick,
    hasStartedVerification,
    apiErrors,
  };
};

export default usePhoneVerification;
