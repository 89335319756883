// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Plasmic_020ErfassungVerification01,
  Default_020ErfassungVerification01Props,
} from "./plasmic/remms_4_all/Plasmic_020ErfassungVerification01";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useWatch } from "react-hook-form";
import usePhoneVerification from "../usePhoneVerification";
import { useEffect } from "react";

export interface _020ErfassungVerification01Props extends Default_020ErfassungVerification01Props {
  onPhoneVerificationApiErrors?: (errors: Record<string, string>) => void;
  isSubmitting?: boolean;
  address: string;
}

function _020ErfassungVerification01_(
  { onPhoneVerificationApiErrors, isSubmitting, address, ...props }: _020ErfassungVerification01Props,
  ref: HTMLElementRefOf<"div">,
) {
  const { user_agreement, phone_number } = useWatch();
  const phoneVerification = usePhoneVerification(phone_number);

  useEffect(
    () => onPhoneVerificationApiErrors?.(phoneVerification.apiErrors),
    [onPhoneVerificationApiErrors, phoneVerification.apiErrors],
  );

  return (
    <Plasmic_020ErfassungVerification01
      root={{ ref }}
      {...props}
      submitButton={{
        isDisabled: !user_agreement || !phoneVerification.hasStartedVerification || isSubmitting,
        isLoading: isSubmitting,
      }}
      phoneVerificationButton={{
        onClick: phoneVerification.onPhoneVerifyClick,
        isDisabled: phoneVerification.isPhoneVerificationButtonDisabled,
      }}
      processHeader={{ address }}
    />
  );
}

const _020ErfassungVerification01 = React.forwardRef(_020ErfassungVerification01_);
export default _020ErfassungVerification01;
