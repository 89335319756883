// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicNavButton, DefaultNavButtonProps } from "./plasmic/remms_4_all/PlasmicNavButton";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useLocation } from "react-router-dom";
import { scrollElementIntoView } from "../utils";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface NavButtonProps extends Omit<DefaultNavButtonProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultNavButtonProps altogether and have
// total control over the props for your component.
export interface NavButtonProps extends DefaultNavButtonProps {}

function NavButton_({ scrollTo, ...props }: NavButtonProps, ref: HTMLElementRefOf<"div">) {
  const hash = decodeURIComponent(useLocation().hash);

  const isActive = hash === `#${scrollTo}`;

  return (
    <PlasmicNavButton
      root={{ ref }}
      {...props}
      isActive={isActive}
      onClick={() => scrollTo && scrollElementIntoView(scrollTo)}
    />
  );
}

const NavButton = React.forwardRef(NavButton_);
export default NavButton;
