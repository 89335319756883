import { apiEndpoint } from "./apiEndpoint";
import {
  AddressSearch,
  GuestRealtyCreationRequest,
  GuestRealtyCreationResponse,
  Locale,
  RequestPasswordResetLinkRequest,
  ResetPasswordRequest,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  Translation,
  TranslationsRequest,
  VerifyPhoneNumberRequest,
} from "./types";

export const PublicApiService = {
  session: {
    signIn: apiEndpoint<SignInRequest, SignInResponse>({
      path: "/users/sign_in",
      method: "POST",
    }),
    signUp: apiEndpoint<SignUpRequest, object>({
      path: "/users",
      method: "POST",
    }),
  },

  password: {
    requestReset: apiEndpoint<RequestPasswordResetLinkRequest, undefined>({
      path: "/users/password",
      method: "POST",
    }),
    reset: apiEndpoint<ResetPasswordRequest, undefined>({
      path: "/users/password",
      method: "PATCH",
    }),
  },

  verify_phone_number: {
    start: apiEndpoint<VerifyPhoneNumberRequest, object>({
      path: "/verify_phone_number",
      method: "POST",
    }),
  },

  realties: {
    create: apiEndpoint<GuestRealtyCreationRequest, GuestRealtyCreationResponse>({
      path: "/submit_guest_realty",
      method: "POST",
    }),
  },

  addressSearch: {
    suggestions: apiEndpoint<{ query: string }, AddressSearch["SuggestionResponse"]>({
      path: "/address_search/suggestions",
      method: "GET",
    }),
    details: apiEndpoint<{ place_id: string }, AddressSearch["DetailsResponse"]>({
      path: "/address_search/details",
      method: "GET",
    }),
  },

  translations: {
    locale: (locale: Locale) => {
      return {
        get: apiEndpoint<undefined, Translation>({
          path: `translations/${locale}`,
          method: "GET",
        }),
        patch: apiEndpoint<TranslationsRequest, undefined>({
          path: `translations/${locale}`,
          method: "PATCH",
        }),
      };
    },
    keys: apiEndpoint<undefined, [string]>({
      path: `translations/keys`,
      method: "GET",
    }),
  },
};
