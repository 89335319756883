import * as React from "react";
import { PlasmicDynamicPowerList, DefaultDynamicPowerListProps } from "./plasmic/remms_4_all/PlasmicDynamicPowerList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext } from "react-hook-form";
import flattenChildren from "react-keyed-flatten-children";
import { isValidElement, ReactElement, useState } from "react";
import DynamicPowerListItem from "./DynamicPowerListItem";

export interface DynamicPowerListProps extends DefaultDynamicPowerListProps {}

function DynamicPowerList_({ items, ...props }: DynamicPowerListProps, ref: HTMLElementRefOf<"div">) {
  const { getValues } = useFormContext();
  const selectableEntries = ["ENR_ENTR_STRMEGT_KAT", "ENR_ENTR_STRMIET_KAT", "ENR_ENTR_PVSTROM_KAT"];
  const [selectedEntries, setSelectedEntries] = useState<Array<boolean>>(
    getValues(selectableEntries).map((value) => !!value),
  );

  const onAdd = (index: number) => {
    setSelectedEntries((selectedEntries) => {
      selectedEntries[index] = true;
      return [...selectedEntries];
    });
  };

  const onRemove = (index: number) => {
    setSelectedEntries((selectedEntries) => {
      selectedEntries[index] = false;
      return [...selectedEntries];
    });
  };

  items = flattenChildren(items || [])
    .map((item, index) => {
      if (isValidElement(item) && item.type === DynamicPowerListItem) {
        return React.cloneElement(item as ReactElement, {
          onDelete: () => {
            onRemove(index);
          },
        });
      }
      return item;
    })
    .filter((_item, index) => selectedEntries[index]);

  return (
    <PlasmicDynamicPowerList
      root={{ ref }}
      {...props}
      items={items}
      select={{
        onChange: (value: number | null) => {
          if (value === null) {
            return;
          }
          onAdd(value - 1);
        },
      }}
      hiddenAddButton={selectedEntries.filter((x) => x).length === selectableEntries.length}
    />
  );
}

const DynamicPowerList = React.forwardRef(DynamicPowerList_);
export default DynamicPowerList;
