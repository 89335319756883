// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicBenutzereinstellungen,
  DefaultBenutzereinstellungenProps,
} from "./plasmic/remms_4_all/PlasmicBenutzereinstellungen";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import AuthToken from "../api/AuthToken";
import { WithAuthenticatedApiServiceProps, withAuthenticatedApiService } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { useApiErrorHandler } from "../useApiErrorHandler";
import LoadingScreen from "./custom/LoadingScreen";
import { RegisteredUserResponse } from "../api/types";
import { useLocation } from "react-router-dom";
import { scrollElementIntoView } from "../utils";

export interface BenutzereinstellungenProps
  extends DefaultBenutzereinstellungenProps,
    WithAuthenticatedApiServiceProps {}

function Benutzereinstellungen_(
  { authenticatedApiService, ...props }: BenutzereinstellungenProps,
  ref: HTMLElementRefOf<"div">,
) {
  const handleApiError = useApiErrorHandler()[1];

  const fullName = AuthToken.get()?.fullName();
  const [userSettings, setUserSettings] = React.useState<RegisteredUserResponse | undefined>(undefined);
  const location = useLocation();

  React.useEffect(() => {
    authenticatedApiService.currentUser.get().then((response) => {
      if (isSuccessful(response)) {
        setUserSettings(response.data);
      } else {
        handleApiError(response);
      }
    });
  }, [authenticatedApiService, handleApiError]);

  React.useEffect(() => {
    if (userSettings && location.hash) {
      setTimeout(() => {
        scrollElementIntoView(location.hash.substring(1));
      });
    }
  }, [userSettings, location.hash]);

  if (userSettings === undefined) {
    return <LoadingScreen />;
  }

  return (
    <PlasmicBenutzereinstellungen
      root={{ ref }}
      userFullName={fullName}
      usageAgreementUserSettings={{
        termsOfUse: userSettings.terms_of_use,
      }}
      generalUserSettings={{
        email: userSettings.email,
        phoneNumber: userSettings.phone_number,
        portfolio: userSettings.portfolio,
      }}
      addressUserSettings={{
        address: userSettings.address,
        billingAddress: userSettings.billing_address,
      }}
      benchmarkUserSettings={{
        esgBenchmark: userSettings.esg_benchmark,
      }}
      {...props}
    />
  );
}

const Benutzereinstellungen = withAuthenticatedApiService(React.forwardRef(Benutzereinstellungen_));
export default Benutzereinstellungen;
