import * as React from "react";
import {
  PlasmicReportSuccessContent,
  DefaultReportSuccessContentProps,
} from "./plasmic/remms_4_all/PlasmicReportSuccessContent";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { getValOfPlasmicComponent } from "../utils";
import { useTranslation } from "react-i18next";
import { ReportSuccessHeaderProps } from "./ReportSuccessHeader";

export interface ReportSuccessContentProps extends DefaultReportSuccessContentProps {
  email: string;
  reportSuccessHeader: ReportSuccessHeaderProps;
}

function ReportSuccessContent_(
  { email, reportSuccessHeader, ...props }: ReportSuccessContentProps,
  ref: HTMLElementRefOf<"div">,
) {
  const { t } = useTranslation();

  return (
    <PlasmicReportSuccessContent
      root={{ ref }}
      {...props}
      reportSuccessHeader={reportSuccessHeader}
      successBanner={{
        render: (bannerProps, Component) => {
          return <Component {...bannerProps} title={t(getValOfPlasmicComponent(bannerProps.title), { email })} />;
        },
      }}
    />
  );
}

const ReportSuccessContent = React.forwardRef(ReportSuccessContent_);
export default ReportSuccessContent;
