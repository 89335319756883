import React from "react";
import { Feature } from "./api/types";
import { AuthenticatedApiService } from "./api/AuthenticatedApiService";
import { isSuccessful } from "./api/apiEndpoint";
import { useApiErrorHandler } from "./useApiErrorHandler";

export const useFeature = (feature: Feature, authenticatedApiService: AuthenticatedApiService): boolean | undefined => {
  const [featureEnabled, setFeatureEnabled] = React.useState<boolean | undefined>(undefined);
  const handleApiError = useApiErrorHandler()[1];

  React.useEffect(() => {
    authenticatedApiService.features.get().then((response) => {
      if (isSuccessful(response)) {
        setFeatureEnabled(response.data.includes(feature));
      } else {
        handleApiError(response);
      }
    });
  }, [authenticatedApiService, handleApiError, feature]);

  return featureEnabled;
};
