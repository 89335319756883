import * as React from "react";
import {
  PlasmicObjectDetailRow,
  DefaultObjectDetailRowProps,
  PlasmicObjectDetailRow__OverridesType,
} from "./plasmic/remms_4_all/PlasmicObjectDetailRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { formatRatingNumber } from "../utils";

interface RatingProps {
  oekonomieRatingParam: number | string;
  oekonomieRisikoParam: boolean;
  oekologieRatingParam: number | string;
  oekologieRisikoParam: boolean;
  sozialRatingParam: number | string;
  sozialRisikoParam: boolean;
}

export interface ObjectDetailRowProps extends DefaultObjectDetailRowProps, PlasmicObjectDetailRow__OverridesType {
  ratings: RatingProps;
}

function ObjectDetailRow_(props: ObjectDetailRowProps, ref: HTMLElementRefOf<"div">) {
  const { ratings, ...rest } = props;
  return (
    <PlasmicObjectDetailRow
      root={{ ref }}
      {...rest}
      hasReferenceNr={!!rest.refNr}
      hasVersion={!!rest.version}
      oekonomieRating={{
        ratingNumber: formatRatingNumber(ratings.oekonomieRatingParam),
        hasWarning: ratings.oekonomieRisikoParam,
      }}
      oekologieRating={{
        ratingNumber: formatRatingNumber(ratings.oekologieRatingParam),
        hasWarning: ratings.oekologieRisikoParam,
      }}
      sozialRating={{
        ratingNumber: formatRatingNumber(ratings.sozialRatingParam),
        hasWarning: ratings.sozialRisikoParam,
      }}
      isDraft={!rest.reportAvailable}
    />
  );
}

const ObjectDetailRow = React.forwardRef(ObjectDetailRow_);
export default ObjectDetailRow;
