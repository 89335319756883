import React, { ReactElement } from "react";
import { Controller, FieldError, useFormContext, Validate } from "react-hook-form";
import { registerComponent } from "@plasmicapp/react-web/lib/host";
import { pickBy, Dictionary } from "lodash";
import { isPresent } from "../../utils";
import Select from "../Select";
import Select__Option from "../Select__Option";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";

interface WiredFormFieldProps {
  className: string;
  name: string;
  required?: boolean;
  children?: React.ReactElement;
}

const errorMessageForError = (error: FieldError) => {
  if (error.message) return error.message;
  if (error.type === "present") return "Bitte füllen Sie dieses Feld aus";
};

const patchChildElement = (
  childElement: ReactElement,
  field: object,
  invalid: boolean,
  name: string,
  t: TFunction,
  required?: boolean,
) => {
  let props: object = {
    ...field, // includes value and onChange
    state: invalid ? "error" : undefined,
    "aria-invalid": invalid,
    "data-testid": name,
  };

  // Add reset option for optional Select fields
  if (childElement.type === Select && !required) {
    if (childElement.props.options) {
      const emptyElement = { value: null, label: t("no-selection") };
      props = { ...props, options: [emptyElement, ...childElement.props.options] };
    } else if (childElement.props.children?.length) {
      const emptyElement = <Select__Option value={null}> {t("no-selection")}</Select__Option>;
      props = { ...props, children: [emptyElement, ...childElement.props.children] };
    }
  }

  return React.cloneElement(childElement as ReactElement, props);
};

const WiredFormField: React.FunctionComponent<WiredFormFieldProps> = ({ name, required, children }) => {
  const { t } = useTranslation();
  const context = useFormContext();
  const rules = {
    validate: pickBy({ present: required && isPresent }) as Dictionary<Validate<string, unknown>>,
  };

  return (
    <Controller
      control={context.control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { invalid, error } }) => {
        if (!React.isValidElement(children)) return <></>;

        const childElement = patchChildElement(children as ReactElement, field, invalid, name, t, required);

        return (
          <>
            {childElement}
            {/* TODO: Error message sollte in den entsprechenden Slot, sobald Henry diesen implementiert hat */}
            {invalid && error && (
              <span role="alert" style={{ color: "red", fontSize: ".75em" }}>
                {errorMessageForError(error)}
              </span>
            )}
          </>
        );
      }}
    />
  );
};

export default WiredFormField;

export const registerWiredFormField = () => {
  registerComponent(WiredFormField, {
    name: "WiredFormField",
    displayName: "Wired FormField",
    importPath: "./app/javascript/components/custom/WiredFormField",
    isDefaultExport: true,
    props: {
      children: {
        type: "slot",
        hidePlaceholder: true,
      },
      name: {
        type: "string",
      },
      required: {
        type: "boolean",
        defaultValue: false,
        displayName: "Is Required?",
      },
    },
  });
};
