export const AVAILABLE_LOCALES = ["de", "fr", "it", "en"] as const;
export type Locale = (typeof AVAILABLE_LOCALES)[number];

export interface Translation {
  [key: string]: string;
}

export interface TranslationsRequest {
  translations: Translation;
}

export interface SignInRequest {
  registered_user: {
    email: string;
    password: string;
  };
}

export interface SignInResponse {
  email: string;
}

export interface SignUpRequest {
  registered_user: {
    email: string;
    password: string;
    password_confirmation: string;
    phone_number: string;
    verification_code: string;
  };
}

export const AVAILABLE_FEATURES = ["portfolio"] as const;
export type Feature = (typeof AVAILABLE_FEATURES)[number];
export type FeatureResponse = Feature[];

export interface RequestPasswordResetLinkRequest {
  registered_user: {
    email: string;
  };
}

export interface ImportResponse {
  import_enabled: boolean;
  import: {
    state: "pending" | "done" | "failed";
  };
}

export interface ImportRequest {
  import: {
    xlsx_file: string;
  };
}

export interface ResetPasswordRequest {
  registered_user: {
    reset_password_token: string;
    password: string;
    password_confirmation: string;
  };
}

export type ResetPasswordForm = Omit<ResetPasswordRequest["registered_user"], "reset_password_token">;

export interface VerifyPhoneNumberRequest {
  phone_number: string;
}

export interface RealtyListEntry {
  id: string;
  created_at: string;
  archived_at: string;
  address: RealtyAddress;
  active_snapshot: RealtySnapshotResponse;
}

export type ListRealtiesResponse = RealtyListEntry[];

// This is mainly a subset of RemmsUserInput (FPRE API: /Assessment)
export type RealtyAddress = {
  BUILDING_STREET: string;
  BUILDING_NUMBER: string;
  BUILDING_ZIPCODE: string;
  BUILDING_CITY: string;
  BUILDING_COUNTRY: 1 | 2 | 3; // see Fpre::Client::COUNTRY in app/services/fpre/client.rb
};

export type ISO8601DateIsh = `${number}-${number}-${number}`;

// This is mainly a subset of RemmsUserInput (FPRE API: /Assessment)
export type Remms4allForm = {
  R4A_GUI_DET: RealtyFormType;

  detailedReport: boolean; // Virtual form field for OBJ_BERICHT_DETAIL

  REF_NR: string;
  VERSION: string;

  realtyDataReferralDate: ISO8601DateIsh; // Virtual form field
  DATSTANDIMMOBJAHR: number;
  DATSTANDIMMOBMON: number;
  DATSTANDIMMOBTAG: number;

  energyDataReferralDate?: ISO8601DateIsh; // Virtual form field
  DATSTANDENERGJAHR?: number;
  DATSTANDENERGMON?: number;
  DATSTANDENERGTAG?: number;

  marketValueDataReferralDate?: ISO8601DateIsh; // Virtual form field
  DATSTANDMARKTWJAHR?: number;
  DATSTANDMARKTWMON?: number;
  DATSTANDMARKTWTAG?: number;

  geakCertifiedAt?: ISO8601DateIsh; // Virtual form field
  GEAK_EA_DAT_J?: number;
  GEAK_EA_DAT_M?: number;
  GEAK_EA_DAT_T?: number;

  geakCertifiedUntil?: ISO8601DateIsh; // Virtual form field
  GEAK_EA_GUEL_J?: number;
  GEAK_EA_GUEL_M?: number;
  GEAK_EA_GUEL_T?: number;

  minergieCertifiedAt?: ISO8601DateIsh; // Virtual form field
  MINERGIEDATUM_DEKL_J?: number;
  MINERGIEDATUM_DEKL_M?: number;
  MINERGIEDATUM_DEKL_T?: number;

  [key: string]: string | number | boolean | undefined;
};

export type BenchmarkUserSettingsForm = {
  BM_AUSW_EIG_TYP?: number;
  BM_AUSW_IMM_KAT?: number;
  BM_AUSW_REG?: number;
};

// This is mainly a subset of RemmsUserInput (FPRE API: /Assessment)
export type ESGQuestionnaireForm = {
  portfolioDataReferralDate?: ISO8601DateIsh; // Virtual form field
  ESG_QUEST_ORG?: string;
  NVB_ABDKG_DEKL?: number;
  [key: string]: string | number | boolean | undefined;
};

// This is mainly a subset of AssessmentResponse (FPRE API: /Assessment)
export type SnapshotReport = {
  id: number;
  MODVERSOUT: string;
  DATENSTAND_IMMOWERT_TAG: string;
  DATENSTAND_IMMOWERT_MONAT: string;
  DATENSTAND_IMMOWERT_JAHR: string;
  VERBRAUCH_GESAMTENERG: number | string;
  EMISSIONEN_GESAMTENERG: number | string;
  INVEST_KOSTEN_NETTO: number | string;
  GESAMTRAT_OEKONOMIE: number | string;
  GESAMTRAT_OEKOLOGIE: number | string;
  GESAMTRAT_SOZIALES: number | string;
  RISKIND_GESMT_OEKON: number;
  RISKIND_GESMT_OEKOL: number;
  RISKIND_GESMT_SOZ: number;
};

export interface BannerEntry {
  id: number;
  title: string | null;
  text: string | null;
}

export interface ReportResponse {
  id: number;
  pdf_report_state: "pending" | "done" | "failed";
  pdf_report_url: string | null;
  api_request: RealtyAddress & { REF_NR: string; VERSION: string };
}

export interface RealtyResponse {
  id: number;
  address_form: RealtyAddress;
}

export interface RealtySnapshotResponse {
  id: number;
  active: boolean;
  remms4all_form: Remms4allForm;
  snapshot_report: SnapshotReport;
}

export interface PortfolioSnapshotResponse extends RealtySnapshotResponse {
  realty_id: number;
  realty_address: RealtyAddress;
}

export interface PortfolioSnapshotListEntry {
  id: string;
  snapshotReportLink: string;
  verbrauch: string;
  emission: string;
  dataDate: string;
  modelDate: string;
  refNr: string;
  version: string;
  address: string;
  oekonomieRating: string;
  oekologieRating: string;
  sozialRating: string;
}

export interface ReportId {
  realty_report_id: number;
}

export interface SnapshotId {
  snapshot_id: number;
}

export interface Address {
  salutation: "male" | "female";
  first_name: string;
  last_name: string;
  street: string;
  zip_code: string;
  city: string;
  organization: string;
  contract_number: string;
}

export interface AddressForm extends Omit<Address, "salutation"> {
  salutation: 0 | 1;
}

// This is mainly a subset of RemmsUserInput (FPRE API: /Assessment)
export interface TermsOfUse {
  NVB_EV_LIEF_PF: boolean;
  NVB_BST_AUFRICH_LIEF: boolean;
  NVB_BST_NO_SELEC: boolean;
  NVB_EV_GEAK_ABKL: boolean;

  NVB_EV_DS_INKL_ADR: boolean;
  NVB_EV_DS_EXKL_ADR: boolean;
  NVB_EV_DV_BM: boolean;
  NVB_EV_DURCHL_PLZ: boolean;

  NVB_EV_LIEF_BFS: boolean;
  NVB_EV_LIEF_FINZ: boolean;
  NVB_EV_FAIRUSE: boolean;
}

// This is mainly a subset of RemmsUserInput (FPRE API: /Assessment)
export const DefaultTermsOfUseForm = {
  NVB_EV_LIEF_PF: true,
  NVB_BST_AUFRICH_LIEF: true,
  NVB_BST_NO_SELEC: true,
  NVB_EV_GEAK_ABKL: true,

  data_usage: "NVB_EV_DS_INKL_ADR",

  NVB_EV_LIEF_BFS: true,
  NVB_EV_LIEF_FINZ: true,
  NVB_EV_FAIRUSE: true,
};

export type TermsOfUseForm = typeof DefaultTermsOfUseForm;

export interface UserUpdateRequest {
  email?: string;
  portfolio?: boolean;
  address_attributes?: Address;
  billing_address_attributes?: Address;
  terms_of_use?: TermsOfUse;
  esg_questionnaire_attributes?: ESGQuestionnaireForm;
  remove_billing_address?: boolean;
  esg_benchmark_attributes?: BenchmarkUserSettingsForm;
}

export interface RegisteredUserResponse {
  email: string;
  phone_number: string;
  portfolio: boolean;
  address?: Address;
  billing_address?: Address;
  esg_questionnaire?: ESGQuestionnaireForm;
  terms_of_use?: TermsOfUse;
  esg_benchmark?: BenchmarkUserSettingsForm;
}

export interface PasswordUpdateRequest {
  registered_user: {
    current_password: string;
    password: string;
    password_confirmation: string;
  };
}

export interface RegistrationAddressForm {
  address_attributes: Address;
  billing_address_attributes?: Address;
  terms_of_use: TermsOfUseForm;
}

export enum RealtyFormType {
  simple = 1,
  detailed = 2,
}

export type AddressSearch = {
  SuggestionResponse: Array<{ description: string; place_id: string }>;
  DetailsResponse: {
    route: string;
    street_number: string;
    postal_code: string;
    locality: string;
    country: string;
  };
};

export type RealtyCreationRequest = {
  realty: { address: RealtyAddress };
  realty_snapshot: { remms4all_form: Remms4allForm };
};

export type GuestRealtyCreationRequest = {
  user: {
    phone_number: string;
    verification_code: string;
    email: string;
  };
  realty: { address: RealtyAddress };
  realty_snapshot: { remms4all_form: Remms4allForm };
};

export interface GuestRealtyCreationResponse {
  email: string;
  remaining_count: number;
  ref_nr: string | null;
  version: string | null;
  address: RealtyAddress;
}

export const GUEST_REALTY_ADDRESS_STORAGE_KEY = "guest.address";
export const GUEST_REALTY_FORM_DATA_STORAGE_KEY = "guest.formData";
export const GUEST_REALTY_REPORT_CREATED_STORAGE_KEY = "guest.reportCreated";

export type GuestVerificationForm = {
  phone_number: string;
  verification_code: string;
  email: string;
  user_agreement: boolean;
};

export type ShowLatestPortfolioResponse = {
  portfolio_enabled: boolean;
  esg_questionnaire?: ESGQuestionnaireForm;
  portfolio_version?: PortfolioVersion;
};

export type PortfolioVersion = {
  id: number;
  name: string;
  coverage: number;
  energy_data_referral_date: string;
  realty_data_referral_date: string;
  created_at: string;
  updated_at: string;
  portfolio_report?: PortfolioReport;
  pdf_report_url?: string;
  realty_snapshots_count: number;
};

export interface PortfolioListEntry {
  id: number;
  created_at: string;
  name: string;
  energy_data_referral_date: string;
  coverage: number;
  portfolio_report?: PortfolioReport;
  pdf_report_url: string;
}

export interface PortfolioReport {
  id: number;
  pdf_report_url?: string;
  pdf_report_state: "success" | "pending" | "failed";
  api_response: {
    PF_GESAMTRAT_OEKOLOGIE_EBF_MW: number;
    PF_GESAMTRAT_OEKONOMIE_EBF_MW: number;
    PF_GESAMTRAT_SOZIALES_EBF_MW: number;
    PF_SC12_VERB_TOT: number;
    PF_SC12_VERB_M2EBF_LABEL: string;
    PF_SC12_VERB_M2VMF_LABEL: string;
    PF_SC12_ANTEIL_FOSSIL_ENERG: number;
    PF_SC12_ANTEIL_ERNEU_ENERG: number;
    PF_SC12_ANTEIL_ABWAERM_ENERG: number;
    PF_SC12_EMISS_TOT: number;
    PF_SC12_EMISS_M2EBF_LABEL: string;
    PF_SC12_EMISS_M2VMF_LABEL: string;
    PF_SC12_GRUENERCAPEX_30: number;
    PF_SC12_GRUENERCAPEX_40: number;
    PF_SC12_GRUENERCAPEX_50: number;
    PF_ABDECKUNGSGRAD_OUTPUT: number | null;
  };
}

export type PortfolioForm = {
  name: string;
  coverage?: number;
  energy_data_referral_date: ISO8601DateIsh;
  realty_data_referral_date: ISO8601DateIsh;
};
