import * as React from "react";
import {
  PlasmicBooleanSelectButtonGroup,
  DefaultBooleanSelectButtonGroupProps,
} from "./plasmic/remms_4_all/PlasmicBooleanSelectButtonGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { isNil } from "lodash";

export interface BooleanSelectButtonGroupProps extends DefaultBooleanSelectButtonGroupProps {
  // value can be unknown if the form is empty, a boolean when something is selected or null if the user deselects.
  value?: boolean | null;
  onChange?: (value?: boolean | null) => void;
}

function BooleanSelectButtonGroup_(
  { value, onChange, ...props }: BooleanSelectButtonGroupProps,
  ref: HTMLElementRefOf<"div">,
) {
  const convertedValue = isNil(value) ? value : value ? 1 : 0;
  const convertedOnChange = (value: number | undefined | null) => {
    onChange && onChange(isNil(value) ? value : !!value);
  };

  return (
    <PlasmicBooleanSelectButtonGroup
      root={{ ref }}
      {...props}
      buttonGroup={{ value: convertedValue, onChange: convertedOnChange }}
    />
  );
}

const BooleanSelectButtonGroup = React.forwardRef(BooleanSelectButtonGroup_);
export default BooleanSelectButtonGroup;
