import * as React from "react";
import ThumbWrapper from "./ThumbWrapper";

interface ThumbProps extends React.HTMLAttributes<unknown> {
  "data-index"?: number;
  element: React.ReactElement;
}

export default function Thumb(props: ThumbProps) {
  const { children, element, ...other } = props;

  return (
    <ThumbWrapper {...(other as object)} zIndex={2} data-testid="slider-thumb">
      {element}
      {children}
    </ThumbWrapper>
  );
}
