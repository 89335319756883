// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Plasmic_008ErfassungAdresse,
  Default_008ErfassungAdresseProps,
} from "./plasmic/remms_4_all/Plasmic_008ErfassungAdresse";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { RealtyFormType } from "../api/types";
import { RealtyTypeSwitchValue } from "./NewRealtyAddress";

export interface _008ErfassungAdresseProps extends Default_008ErfassungAdresseProps {
  isLoggedIn: boolean;
}

function _008ErfassungAdresse_({ isLoggedIn, ...props }: _008ErfassungAdresseProps, ref: HTMLElementRefOf<"div">) {
  const { realtyType, formType } = useWatch();
  const { setValue } = useFormContext();
  const [formTypeDisabled, setFormTypeDisabled] = React.useState(false);

  useEffect(() => {
    if (realtyType === RealtyTypeSwitchValue.other) {
      setFormTypeDisabled(true);

      if (formType !== RealtyFormType.detailed) setValue("formType", RealtyFormType.detailed);
    } else {
      setFormTypeDisabled(false);
    }
  }, [realtyType, formType, setValue]);

  return (
    <Plasmic_008ErfassungAdresse
      root={{ ref }}
      navigation={{ isLoggedIn }}
      simpleFormButton={{ isDisabled: formTypeDisabled }}
      detailedFormButton={{ isDisabled: formTypeDisabled }}
      {...props}
    />
  );
}

const _008ErfassungAdresse = React.forwardRef(_008ErfassungAdresse_);
export default _008ErfassungAdresse;
