// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LargeInfoIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LargeInfoIcon(props: LargeInfoIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 48 48"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeWidth={"1"}
        transform={"translate(.5 .5)"}
        fill={"none"}
        stroke={"currentColor"}
      >
        <circle
          cx={"24"}
          cy={"12"}
          r={"1"}
          fill={"currentColor"}
          stroke={"none"}
        ></circle>

        <circle cx={"24"} cy={"24"} r={"22"}></circle>

        <circle cx={"24"} cy={"12"} r={"1"} stroke={"currentColor"}></circle>

        <path stroke={"currentColor"} d={"M24 19v17"}></path>
      </g>
    </svg>
  );
}

export default LargeInfoIcon;
/* prettier-ignore-end */
