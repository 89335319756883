import * as React from "react";
import { PlasmicProcessHeader, DefaultProcessHeaderProps } from "./plasmic/remms_4_all/PlasmicProcessHeader";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ProcessHeaderProps extends DefaultProcessHeaderProps {
  address: string;
}

function ProcessHeader_(props: ProcessHeaderProps, ref: HTMLElementRefOf<"div">) {
  return <PlasmicProcessHeader root={{ ref }} {...props} />;
}

const ProcessHeader = React.forwardRef(ProcessHeader_);
export default ProcessHeader;
