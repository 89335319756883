import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";

import lng_de from "./locales/de.json";
import lng_en from "./locales/en.json";
import lng_fr from "./locales/fr.json";
import lng_it from "./locales/it.json";

const supportedLocales = ["de", "en", "fr", "it"];

const apiUrl = window.REMMS4ALL.apiUrl.replace(/\/$/, "");

// There's a good tutorial which explains the nuances of this configuration:
// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/
i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    ns: ["plasmic", "local"],
    defaultNS: "local",
    fallbackLng: "de",
    supportedLngs: supportedLocales,
    nonExplicitSupportedLngs: false, // Disallow to derive variants from main locales
    load: "currentOnly",
    debug: false,
    backend: {
      loadPath: `${apiUrl}/translations/{{lng}}`,
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    returnNull: false,
  });

// Add all the local translations from `./locales/*` to the i18n instance. We use the `local` namespace for these.
i18n.addResourceBundle("de", "local", lng_de);
i18n.addResourceBundle("en", "local", lng_en);
i18n.addResourceBundle("fr", "local", lng_fr);
i18n.addResourceBundle("it", "local", lng_it);

export default i18n;
