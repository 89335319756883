import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { PlasmicTranslator } from "@plasmicapp/react-web/dist/render/translation";

const usePlasmicTranslator = () => {
  const { t } = useTranslation();
  const translator: PlasmicTranslator = (key, opts) => {
    if (opts?.components) {
      return <Trans i18nKey={key} ns={"plasmic"} components={opts.components} />;
    }
    return t(key, { ns: "plasmic" });
  };
  return translator;
};

export default usePlasmicTranslator;
