import * as React from "react";
import {
  Plasmic_022BerichtSuccessNichtRegisriert,
  Default_022BerichtSuccessNichtRegisriertProps,
} from "./plasmic/remms_4_all/Plasmic_022BerichtSuccessNichtRegisriert";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ReportSuccessContentProps } from "./ReportSuccessContent";
import { GuestRealtyCreationResponse } from "../api/types";
import { formatAddress, formatReportAddress } from "../utils";

const remainingCountToSuccessContentState: (remainingCount: number) => ReportSuccessContentProps["state"] = (
  remainingCount,
) => {
  switch (remainingCount) {
    case -1:
      return "reportsUsedUp";
    case 0:
      return "lastReport";
    default:
      return undefined;
  }
};

export interface _022BerichtSuccessNichtRegisriertProps extends Default_022BerichtSuccessNichtRegisriertProps {
  report: GuestRealtyCreationResponse;
}

function _022BerichtSuccessNichtRegisriert_(
  { report, ...props }: _022BerichtSuccessNichtRegisriertProps,
  ref: HTMLElementRefOf<"div">,
) {
  const state = remainingCountToSuccessContentState(report.remaining_count);
  const { email, address, version, ref_nr } = report;

  return (
    <Plasmic_022BerichtSuccessNichtRegisriert
      root={{ ref }}
      {...props}
      reportSuccessContent={{
        state,
        email,
        reportSuccessHeader: { address: formatReportAddress(address, true), version: version, refNr: ref_nr },
      }}
      processHeader={{ address: formatAddress(address) }}
    />
  );
}

const _022BerichtSuccessNichtRegisriert = React.forwardRef(_022BerichtSuccessNichtRegisriert_);
export default _022BerichtSuccessNichtRegisriert;
