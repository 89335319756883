import * as React from "react";
import { PlasmicSelect } from "./plasmic/remms_4_all/PlasmicSelect";
import { SelectRef } from "@plasmicapp/react-web";
import Select__Option from "./Select__Option";
import Select__OptionGroup from "./Select__OptionGroup";
import { isNil } from "lodash";

import { BaseSelectProps } from "@plasmicapp/react-web";

type SelectValue = number | null;

export interface SelectProps extends Omit<BaseSelectProps, "value" | "onChange"> {
  value?: SelectValue;
  onChange?: (value: SelectValue) => void;
}

function Select_({ onChange, ...props }: SelectProps, ref: SelectRef) {
  const delegatedProps = {
    ...props,
    value: isNil(props.value) || isNaN(Number(props.value)) ? null : String(props.value),
    onChange: (value: string | null) => {
      if (value === null) {
        return onChange && onChange(null);
      }
      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue)) {
        console.error(`We don't support non numeric values for Select: ${value}`);
      }
      onChange && onChange(isNaN(numericValue) ? null : numericValue);
    },
  };
  const { plasmicProps } = PlasmicSelect.useBehavior<BaseSelectProps>(delegatedProps, ref);

  return <PlasmicSelect {...plasmicProps} />;
}

const Select = React.forwardRef(Select_);

export default Object.assign(Select, {
  Option: Select__Option,
  OptionGroup: Select__OptionGroup,
  __plumeType: "select",
});
