import styled from "styled-components";
import { SliderTrackProps } from "../../SliderTrack";
import * as React from "react";

const StyledSliderTrackWrapper = styled.div`
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

interface Props extends React.HTMLAttributes<unknown> {
  element: React.ReactElement<SliderTrackProps>;
}

export default function Track({ element, ...props }: Props) {
  return <StyledSliderTrackWrapper {...props}>{element}</StyledSliderTrackWrapper>;
}
