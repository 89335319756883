import { isNil } from "lodash";
import AuthenticationError from "./AuthenticationError";

export default class AuthToken extends String {
  static get(): AuthToken | undefined {
    const retrieved = window.localStorage.getItem("authToken");

    return isNil(retrieved) ? undefined : new AuthToken(retrieved);
  }

  static set(token: string): void {
    const tokenValue = token.match(/Bearer (.*)/)?.[1] ?? token;

    window.localStorage.setItem("authToken", tokenValue);
  }

  static remove(): void {
    window.localStorage.removeItem("authToken");
  }

  isExpired(): boolean {
    const exp = this.getExpFromToken();
    const now = Math.floor(Date.now() / 1000);

    return exp < now;
  }

  userCompletedSignup(): boolean {
    return this.payload()["signup_completed"] === true;
  }

  fullName(): string | undefined {
    return this.payload()["full_name"];
  }

  payload(): any {
    try {
      const tokenComponents = this.split(".");
      return JSON.parse(atob(tokenComponents[1]));
    } catch (e) {
      console.warn(e);
      throw new AuthenticationError("Invalid auth token");
    }
  }

  private getExpFromToken() {
    return this.payload()["exp"];
  }
}
