// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  Plasmic_001PasswordReset01,
  Default_001PasswordReset01Props,
} from "./plasmic/remms_4_all/Plasmic_001PasswordReset01";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import FormWrapper from "./custom/FormWrapper";
import { RequestPasswordResetLinkRequest } from "../api/types";
import { PublicApiService } from "../api/PublicApiService";
import { isSuccessful } from "../api/apiEndpoint";
import { toast } from "react-hot-toast";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useTranslation } from "react-i18next";

export interface _001PasswordReset01Props extends Default_001PasswordReset01Props {}

function _001PasswordReset01_(props: _001PasswordReset01Props, ref: HTMLElementRefOf<"div">) {
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const { t } = useTranslation();

  const onSubmit = async (data: RequestPasswordResetLinkRequest["registered_user"]) => {
    const response = await PublicApiService.password.requestReset({
      registered_user: data,
    });

    if (isSuccessful(response)) {
      toast.success(t("toast.password_requested"));
    } else {
      handleApiError(response);
    }
  };

  return (
    <FormWrapper className={"reset-password"} onSubmit={onSubmit} apiErrors={apiErrors}>
      <Plasmic_001PasswordReset01 root={{ ref }} {...props} />
    </FormWrapper>
  );
}

const _001PasswordReset01 = React.forwardRef(_001PasswordReset01_);
export default _001PasswordReset01;
