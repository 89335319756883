// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LargeAvatarIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LargeAvatarIcon(props: LargeAvatarIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 64 64"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        className={"nc-icon-wrapper"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeWidth={"1"}
        fill={"none"}
        stroke={"currentColor"}
      >
        <path
          data-cap={"butt"}
          d={
            "M32.5 39.5h0c-6.075 0-11-4.925-11-11v-3c0-6.075 4.925-11 11-11h0c6.075 0 11 4.925 11 11v3c0 6.075-4.925 11-11 11z"
          }
          stroke={"currentColor"}
        ></path>

        <path
          d={"M13.5 62.5v-2c0-8.284 6.716-15 15-15h8c8.284 0 15 6.716 15 15v2"}
          stroke={"currentColor"}
        ></path>

        <path d={"M2.5 2.5h60v60h-60z"}></path>
      </g>
    </svg>
  );
}

export default LargeAvatarIcon;
/* prettier-ignore-end */
