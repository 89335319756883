// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicImportExportModal,
  DefaultImportExportModalProps,
} from "./plasmic/remms_4_all/PlasmicImportExportModal";
import FormWrapper from "./custom/FormWrapper";
import { Blob } from "@rails/activestorage";
import { AuthenticatedApiService } from "../api/AuthenticatedApiService";
import { isSuccessful } from "../api/apiEndpoint";
import toast, { LoaderIcon } from "react-hot-toast";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useInterval } from "usehooks-ts";
import { useTranslation } from "react-i18next";

export interface ImportExportModalProps extends DefaultImportExportModalProps {
  directUploadUrl: string;
  authenticatedApiService: AuthenticatedApiService;
  onImportDone: () => void;
}

function ImportExportModal({
  authenticatedApiService,
  directUploadUrl,
  className,
  onImportDone,
  ...props
}: ImportExportModalProps) {
  const [importEnabled, setImportEnabled] = React.useState(false);
  const handleApiError = useApiErrorHandler()[1];
  const [importPending, setImportPending] = React.useState(false);
  const [requestRunning, setRequestRunning] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const updateImportStatus = React.useCallback(() => {
    authenticatedApiService.import.get().then((response) => {
      if (isSuccessful(response)) {
        setImportEnabled(response.data.import_enabled);
        if (response.data.import && response.data.import.state === "pending") {
          setImportPending(true);
        } else {
          if (importPending) {
            onImportDone();
          }
          setImportPending(false);
        }
      } else {
        handleApiError(response);
      }
    });
  }, [authenticatedApiService, handleApiError, importPending, onImportDone]);

  React.useEffect(() => {
    updateImportStatus();
  }, [updateImportStatus]);

  const onUploadDone = (blob: Blob) => {
    authenticatedApiService.import.post({ import: { xlsx_file: blob.signed_id } }).then((response) => {
      if (isSuccessful(response)) {
        toast.success(t("toast.import_started"));
        updateImportStatus();
      } else {
        toast.error(t("toast.import_failed"));
      }
    });
  };

  useInterval(
    () => {
      setRequestRunning(true);
      updateImportStatus();
      setRequestRunning(false);
    },
    importPending && !requestRunning ? 500 : null,
  );

  return (
    <FormWrapper className={className} onSubmit={console.log}>
      <PlasmicImportExportModal
        {...props}
        importRunningIndicator=<LoaderIcon />
        isDisabled={!importEnabled}
        importRunning={importPending}
        fileUploadField={{
          authToken: authenticatedApiService.authToken,
          directUploadUrl,
          onUploadDone,
        }}
      />
    </FormWrapper>
  );
}

export default ImportExportModal;
