import * as React from "react";
import { PlasmicAddressSearchBar, DefaultAddressSearchBarProps } from "./plasmic/remms_4_all/PlasmicAddressSearchBar";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { AutocompleteResultDTO } from "./AutocompleteTextInput";
import { useEffect, useMemo, useState } from "react";
import { AddressSearch } from "../api/types";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { isSuccessful } from "../api/apiEndpoint";
import { useDebounceValue } from "usehooks-ts";
import { PublicApiService } from "../api/PublicApiService";

export interface AddressSearchBarProps extends DefaultAddressSearchBarProps {
  onChange?: (value: AddressDetails | null) => void;
  value?: AddressDetails;
}

// This is used to display the `location` in a nicely formatted string.
// It will format it properly even if parts of it (like the street) are missing.
// => meaning it will show "8260 Stein am Rhein" if you just select the city without any particular house address

export const parseLocationString = (address?: AddressDetails | null): string => {
  if (!address) return "";

  return [
    [address.route, address.street_number],
    [address.postal_code, address.locality],
  ]
    .map((arr) => arr.filter(Boolean).join(" "))
    .filter(Boolean)
    .join(", ");
};

type AddressSuggestions = AddressSearch["SuggestionResponse"];
type AddressSuggestion = AddressSuggestions[0];
type AddressDetails = AddressSearch["DetailsResponse"];

function AddressSearchBar_({ onChange, value, ...props }: AddressSearchBarProps, ref: HTMLElementRefOf<"div">) {
  const [address, setAddress] = useState<AddressDetails | null>(value ?? null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<AddressSuggestions>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 300);
  const handleApiError = useApiErrorHandler()[1];

  useEffect(() => {
    (async () => {
      setAddress(null); // Search term change always means reset
      if (debouncedSearchTerm.length < 3) return;

      const response = await PublicApiService.addressSearch.suggestions({ query: debouncedSearchTerm });
      if (isSuccessful(response)) {
        setSuggestions(response.data);
      } else {
        handleApiError(response);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    onChange && onChange(address);
  }, [address, onChange]);

  const handleDropdownSelection = async (selected: AddressSuggestion) => {
    const placeId = selected.place_id;
    setIsInvalid(!placeId);
    if (!placeId) return;

    try {
      const response = await PublicApiService.addressSearch.details({ place_id: placeId });
      if (isSuccessful(response)) {
        setAddress(response.data);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      console.error("Failed to get location details", error);
      setIsInvalid(true);
    }
  };

  const autocompleteResults: Array<AutocompleteResultDTO> = useMemo(
    () =>
      suggestions.map((suggestion) => ({
        label: suggestion.description ?? "",
        onClick: () => handleDropdownSelection(suggestion),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suggestions],
  );

  return (
    <PlasmicAddressSearchBar
      root={{ ref }}
      {...props}
      searchInput={{
        value: (address && parseLocationString(address)) || searchTerm,
        onChange: setSearchTerm,
        results: autocompleteResults,
        placeholder: "Enter your address",
      }}
      addressIsInvalid={isInvalid}

      // The following props should not be used in REMMS:
      // address={parseLocationString(address)}
      // resetButton={{ onClick: handleReset }}
      // isActive={isActive}
    />
  );
}

export default React.forwardRef(AddressSearchBar_);
