import FormWrapper from "./custom/FormWrapper";
import React, { useEffect } from "react";
import _020ErfassungVerification01 from "./_020ErfassungVerification01";
import { useApiErrorHandler } from "../useApiErrorHandler";
import { useSessionStorage } from "usehooks-ts";
import {
  GUEST_REALTY_ADDRESS_STORAGE_KEY,
  GUEST_REALTY_FORM_DATA_STORAGE_KEY,
  GUEST_REALTY_REPORT_CREATED_STORAGE_KEY,
  GuestRealtyCreationResponse,
  GuestVerificationForm,
  RealtyAddress,
  Remms4allForm,
} from "../api/types";
import { PublicApiService } from "../api/PublicApiService";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isPaymentRequiredErrorResponse, isSuccessful } from "../api/apiEndpoint";
import { formatAddress } from "../utils";

const GuestPhoneVerification = () => {
  const [address, setAddress] = useSessionStorage<RealtyAddress | null>(GUEST_REALTY_ADDRESS_STORAGE_KEY, null);
  const [formData, setFormData] = useSessionStorage<Remms4allForm | null>(GUEST_REALTY_FORM_DATA_STORAGE_KEY, null);
  const setReport = useSessionStorage<GuestRealtyCreationResponse | null>(
    GUEST_REALTY_REPORT_CREATED_STORAGE_KEY,
    null,
  )[1];
  const [apiErrors, handleApiError] = useApiErrorHandler();
  const [phoneVerificationApiErrors, setPhoneVerificationApiErrors] = React.useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (address === null) {
      navigate(ApplicationRoutes.getPath("guestRealtyNewAddress"));
    } else if (formData === null) {
      navigate(ApplicationRoutes.getPath("guestRealtyNewForm"));
    }
  }, [address, formData, navigate]);

  const onSubmit = async ({ verification_code, phone_number, email }: GuestVerificationForm) => {
    setIsSubmitting(true);
    const response = await PublicApiService.realties.create({
      user: { verification_code, phone_number, email },
      realty: { address: address! },
      realty_snapshot: { remms4all_form: formData! },
    });
    setIsSubmitting(false);

    const resetForm = () => {
      setFormData(null);
      setAddress(null);
    };

    if (isSuccessful(response)) {
      resetForm();
      setReport(response.data);
      navigate(ApplicationRoutes.getPath("guestReportCreated"));
    } else if (isPaymentRequiredErrorResponse(response)) {
      // 402 Payment Required: Sent when the user has used up all their reports and the current request has not been
      // processed. We send a remainingCount of -1 to indicate that the user has used up all their reports (rather
      // than having just used his last report, which would result in a remainingCount of 0 as well).
      resetForm();
      setReport({ ...response.data!, remaining_count: -1 });
      navigate(ApplicationRoutes.getPath("guestReportCreated"));
    } else {
      handleApiError(response);
    }
  };

  return (
    <FormWrapper
      className={"guestVerification"}
      onSubmit={onSubmit}
      apiErrors={{ ...apiErrors, ...phoneVerificationApiErrors }}
    >
      <_020ErfassungVerification01
        onPhoneVerificationApiErrors={(errors) => setPhoneVerificationApiErrors(errors)}
        isSubmitting={isSubmitting}
        address={formatAddress(address!)}
      />
    </FormWrapper>
  );
};

export default GuestPhoneVerification;
